// -- HEADER -- //
.header-top {
  @include full-bg($coil);
  z-index: 20;
  //display: none;
  //@include break2 {
  //  display: block;
  //}
  &:after {
    border-bottom: 1px solid $border-color;
  }
}

header,
.header-wrapper,
.siteinfo {
  @include full-bg($white);

  &:before {
    border-bottom: 1px solid $border-color;
  }
}
.header-wrapper {
  position: relative;
  z-index: 49;
}
header {
  z-index: 50;
}

// Siteinfo
.siteinfo {
  float: left;
  width: 100%;

  .logo {
    float: left;
    display: block;
    margin: 10px 0;
    img {
      display: block;
      margin: 0;
      width: 100%;
      max-width: 135px;
      @include break2 {
        max-width: 190px;

      }
      height: auto;
    }
  }
  @include break2 {
    width: auto;
  }
}

// menu
.block-menu-menu-language-switcher {
  float: right;
}
.block-system-main-menu {
  float: left;
  width: 100%;

  @include break2 {
    float: right;
    width: auto;
    margin-top: 17px;
  }
}
.header-top {
  position: relative;
  z-index: 60;
}
.header-top-region {
  float: left;
  width: 100%;
}

.header-top-region-mobile {
  float: left;
  width: 100%;
  @include break2 {
    display: none;
  }
  ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
  }
}

.menu-wrapper--mobile {
  position: relative;
  z-index: 20;
  @include break2max {
    float: left;
    width: 100%;

    overflow: hidden;
    min-height: 0;
    max-height: 0;
    @include transition(all $duration $timing);
    .open & {
      min-height: 100%;
      max-height: 100vh;
    }
  }

  @include font-size(16);
  letter-spacing: $letter-spacing-small;
  text-transform: uppercase;
  font-weight: bold;

  float: right;
}
