//.agro_glue-homepage_contact_button {
//  margin-right: $gutter;
//
//}

.agro_glue-homepage_questions {
  position: inherit;
  //position: relative;

  > img {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    margin-left: 0;
    height: 100%;
    width: auto;
    z-index: -1;

    @include transform(translateX(-50%));

    @include break1 {
      display: block;
    }
  }
  .content-wrapper {
    position: relative;
    float: right;
    @include break1 {
      max-width: 50%;
    }

    > a {
      @include button;
      @include break2 {
        margin-right: $gutter;
      }
    }
  }
}

.home-about-region-left {
  position: relative;
  // triangle
  &:after {
    @include break1 {
      content: '';
      display: block;
      position: absolute;
      background: $coil;
      top: 0;
      left: 30%;
      bottom: 0;
      right: 0;
      margin: auto;
      height: 100%;
      width: 100%;
      z-index: -1;
      margin-left: 0;
      @include transform(skew(20deg, 0deg));
    }
  }
}
