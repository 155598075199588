.view-id-downloads {
  float: left;
  width: 100%;

  @include break1 {
    .view-content {
      float: left;
      width: 100%;
      @include display-flex();
      @include flex-wrap(wrap);
    }
  }
  .node {
    @include break1 {
      @include span-columns(6 of 12);
      @include omega(2n);
      @include display-flex();
      @include flex-direction(column);
    }
    @include break2 {
      &:nth-child(2n + 1) {
        clear: none;
      }
      &:nth-child(2n + 2) {
        margin-right: flex-gutter(8);
      }
      @include span-columns(2 of 8);
      @include omega(4n);
      @include display-flex();
      @include flex-direction(column);
    }
  }
}

.views--exp-downloads-panel_pane_1 {
  margin-bottom: 0;
  .ajax-progress-throbber {
    position: absolute;
    right: 0;
    z-index: 20;
  }
}
