.bef-select-as-links {
  background: $coil;
  float: left;
  width: 100%;
  text-transform: uppercase;
  font-weight: bold;
  @include font-size($h2-size);
  margin-bottom: 20px;
  padding: 0 20px;

  @include break1 {
    text-align: center;
  }

  .form-item {
    display: inline-block;
    margin: 7px 25px 7px 0;

    &:last-child {
      margin-right: 0;
    }
  }
  a {
    color: $white;

    &.active {
      padding: 0 7px;
      border-bottom: 1px solid $white;
    }
  }
}

.views-exposed-form {
  .form-type-select {
    &:after {
      content: none;
    }
  }
}
