.field-name-field-main-visual {
  border-bottom: 1px solid $border-color;
  margin-bottom: 15px;
  img {
    margin: 0;
    display: block;
    width: 100%;
    height: auto;
  }
}
.node-page {
  &.view-mode-full {
    .field-name-body {
      margin-bottom: 20px;
    }
  }
}
