.breadcrumb {
  @include font-size(11, 35);

  a,
  span {
    padding-right: 7px;
  }
  a {
    position: relative;
    padding: 0 5px 3px;
    margin-right: -3px;
    &:before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      height: 1px;
      width: 1px;
      background: transparent;
      @include transition(all $duration $timing);
    }
    &:hover {
      &:before {
        width: 100%;
        background-color: $coil;
      }
    }
  }
  .crumbs {
    padding-right: 0;
  }
  .delimiter {
    // font-size: 0;
    // &:before {
    //   content: '/';
    //   @include font-size(16);
    // }
  }
}
