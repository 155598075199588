#sliding-popup.clearfix:after {
  content: "";
}
.eu-cookie-compliance-banner-info {
  box-sizing: border-box;
  &.clearfix:after {
    content: "";
  }

  .popup-content {
    overflow: hidden;
    font-size: 14px;
  }

  .popup-header {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 30px;
    padding-bottom: 30px;
    color: $white;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: -50px;
      left: -50px;
      bottom: 0;
      width: calc(100% + 100px);
      background: $coil;
      z-index: -1;
    }
  }

  #popup-text {
    margin-top: 10px;
    text-align: center;

    @include tablet {
      padding: 0 60px;
    }
    h2 {
      font-size: 18px;
    }
    h2, h3, h4 {
      color: $white;
    }
    a {
      color: $white;
      text-decoration: underline;
    }
    & > *:last-child {
      margin-bottom: 0;
    }
  }

  .language-switch-links {
    li {
      border-color: $white;
    }
    .language-link {
      color: $white;
      font-size: 12px;
      display: block;
      &.active {
        text-decoration: underline;
        font-weight: bold;
      }
    }
  }

  .eu-cookie-compliance-category {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .eu-cookie-compliance-category-switch {
      flex-shrink: 0;
      margin-left: 20px;
    }
  }

  .dropsolid-eucc--buttons {
    flex-direction: column-reverse;
    align-items: center;
    @include tablet {
      flex-direction: row;
      justify-content: space-between;
    }
  }
  .dropsolid-eucc--accept-minimal-wrapper {
    line-height: 16px;
    margin-top: 10px;
    @include tablet {
      margin-top: 0;
    }
  }
  .dropsolid-eucc--accept-minimal {
    font-size: 12px;
    text-decoration: underline;
    color: $coil;
  }
  .dropsolid-eucc--dynamic-accept-wrapper {
    @include tablet {
      flex-shrink: 0;
      margin-left: auto;
    }
  }
  a.dropsolid-eucc--dynamic-accept:not([class^=contextual-links]) {
    font-size: 16px;
    padding: 10px 15px;
    line-height: 22px;
  }

  .button {
    height: auto;
  }
}

/** Color overrides **/
.eu-cookie-compliance-banner.eu-cookie-compliance-banner-info .eu-cookie-compliance-category-label .state-label {
  color: $yellow;
}
.eu-cookie-compliance-banner.eu-cookie-compliance-banner-info .eu-cookie-compliance-category-switch input:checked + .toggle {
  background-color: $yellow;
}
