.paragraphs-item-paragraph-image {
  border-bottom: 1px solid $border-color;
  margin-bottom: 20px;
  img {
    width: 100%;
    height: auto;
    margin: 0;
    display: block;
  }

  .field-type-image {
    display: block;
    margin: 0;
  }
}
