.view-id-vacancies {
  float: left;
  width: 100%;
  .view-header {
    float: left;
    width: 100%;
    text-align: center;
    margin-bottom: 22px;
    .vacancies-order {
      a {
        text-decoration: underline;
        &:hover {
          opacity: .7;
        }

      }
    }
  }

  .view-content {
    float: left;
    width: 100%;
    + a {
      @include button;
      margin-bottom: 30px;
      float: right;
      @include break2 {
        margin-right: $gutter;
      }
    }
  }
}

.views--exp-vacancies-panel_pane_1 {
  margin-bottom: 5px;
}

.view-display-id-panel_pane_1 {
  margin-bottom: 20px;
}
