.view-id-cases {
  &.view-display-id-panel_pane_2,
  &.view-display-id-panel_pane_4 {
    float: left;
    width: 100%;
    margin-bottom: 50px;
    padding: 50px 0 35px;
    @include full-bg($coil);

    body.front & {
      padding: 0;
      margin: 0;
      &:before {
        background-color: $white;
      }

      .node {
        display: none;
        background: $light-grey;
        @include break1 {
          display: block;
        }
        &:first-child {
          display: block;
        }
      }
    }
  }
}
