.node-teaser {
  background: $white;
  border-bottom: 1px solid $border-color;

  .field-type-image {
    img {
      width: 100%;
      height: auto;
    }
  }
}

.view-mode-full {
  h1 {
    margin-top: 0;
    padding-top: 25px;
    padding-left: 30px;
  }
  .field-name-body {
    background: $white;
    padding: 65px 30px;
    @include break1 {
      padding: 65px 70px;
    }
  }
}

// node teaser special flexbox stuff
.node-article,
.node-case {
  &.view-mode-teaser {
    .field-name-body {
      @include flex(1);
      @include flex-basis;
      padding: 0 10px;
    }

    .field-name-node-link {
      float: right;
    }
    .field-name-field-sector,
    .field-name-node-link {
      @include flex-shrink(1);
      margin-bottom: 10px;
    }
  }
}

.node-article,
.node-case,
.node-izw-detail {
  &.view-mode-teaser {
    .field-name-body {
      margin-bottom: 15px;
      p {
        padding-left: 0;
      }
    }
  }
}

// titles
body.node-type-article,
body.node-type-case {
  h1 {
    //position: relative;
    margin-top: 0;
    margin-bottom: 0;
    background: $white;
    padding-top: 25px;
    padding-left: 30px;
  }
}
