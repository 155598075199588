// -- MAIN STRUCTURE -- //
// -- For structure see http://neat.bourbon.io/
// -- For examples see http://neat.bourbon.io/examples/

// ** BODY
body {
  overflow-x:hidden;
  margin: 0;
  .header-top,
  .header-wrapper,
  .page,
  footer,
  .footer-copyright {
    @include outer-container;
    padding: 0 10px;
    @include break2 {
      padding: 0;
    }
  }
}

// ** HEADER - FOOTER
.header-wrapper *,
.main *,
footer * {
  box-sizing: border-box;
}
//.header-top-region,
//header,
//footer,
//.footer-copyright-region {
//  @include outer-container;
//}

// ** SIDEBARS
// -- Try to use span-columns to style sidebars and such
