// fields
.field-name-field-download-file {
  a {
    @include button;
    display: block;
  }
  img,
  span.file-size {
    display: none;
  }
}
.field-name-agro-glue-send-mail {
  a {
    @include button;
    display: block;
  }
}

.node-download {
  margin-bottom: 20px;
  text-align: center;
  padding: 0px 10px;

  h2,
  > div {
    margin-bottom: 10px;
  }

  h2 {
    @include flex(1);
    @include flex-basis;
  }
}

