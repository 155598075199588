.footer-city,
.footer-mail {
  margin-bottom: 24px;
}

.block-agro-glue-footer-contact-block {
  color: $coil;
  @include font-size(14, 24);

  img {
    margin: 20px 0;
    max-width: 100%;
    width: 100%;
    height: auto;
  }

  .inner-wrapper,
  .title {
    float: left;
    width: 100%;
    padding: 0 30px;
    background-color: $white;
    @include break1 {
      padding: 0 flex-gutter(6);
    }
    @include break2 {
      padding: 0 flex-gutter(3);
    }
  }
  .title {
    padding-top: 20px;
    margin-bottom: 0;
    padding-bottom: 10px;
  }

  .all-offices {
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: $letter-spacing-small;
    padding-bottom: 2px;
    margin-bottom: 20px;

    border-bottom: 2px solid $coil;
  }
}
