/**
 * Contains all blocks with dark background and CTA buttons
 */
.cta-block-dark {
  float: left;
  width: 100%;

  margin-bottom: 20px;
  padding: 20px 20px 0;
  background: $coil;
  color: $white;
  text-align: center;

  .pane-title {
    margin-top: 0;
    color: $white;
    @include font-size(14);
    letter-spacing: $letter-spacing-big;
  }

  > a {
    @include button;
    margin-bottom: 20px;
    width: 100%;
  }
}

