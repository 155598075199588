.panel-flexible.contact {

  //.panels-flexible-row {
  //  &.offices {
  //  }
  //}

  .panels-flexible-region {
    &.contact-form {
      padding: 30px;
      background: $white;
      margin-bottom: 65px;

      form {
        @include break1 {
          margin: 0 20px;
        }
      }
    }
  }
  .panels-flexible-row {
    @include break2 {
      .inside {
        @include display-flex();
      }
      .node-office {
        margin-bottom: 45px;
      }
    }
    &.faq {
      .bean--cta_block {
        @include break2 {
          @include span-columns(8);
          @include shift(2);
        }
      }
    }
  }
}
