// -- FONTS -- //

// ** CUSTOM FONTS
// -- Use Google fonts when you can!
// @include font-face(SourceSansPro, '../fonts/Source_Sans_Pro/SourceSansPro-Regular');
// @include font-face(SourceSansPro, '../fonts/Source_Sans_Pro/SourceSansPro-Bold', bold);
// @include font-face(SourceSansPro, '../fonts/Source_Sans_Pro/SourceSansPro-Italic', normal, italic);

// Google fonts
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// Webtype
// @import url('http://cloud.webtype.com/css/CXXXX.css');

// ** FONTAWESOME
// -- Don't include it if you don't use it!
@include font-face(FontAwesome, '../fonts/fontAwesome/fontawesome-webfont');
