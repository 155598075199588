.izw_feature-izw_feature_aanbod_items {
  background-color: $white;
  //padding-left: 110px;
  position: relative;
  z-index: 5;

  @include break2 {
    padding: 12px 2*flex-gutter(8);
  }
  > * {
    padding-left: 35px;
    padding-right: 35px;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    height: 100%;
    width: 100vw;
    left: auto;
    right: calc(100% - 100px);
    top: 0;
    bottom: 0;
    margin: auto;
    margin-left: calc((100vw - 100%) / 2);

    z-index: -1;
    background: {
      image: url('../images/yellow-block.svg');
      color: $white;
      repeat: no-repeat;
      size: cover;
      position: right bottom;
    }
  }

  > ul { // bypass contextual
    list-style: none;
    margin-left: 15px;
    a {
      text-decoration: underline;
      text-transform: uppercase;
      &:hover {
        opacity: .7;
      }
      //font-weight: bold;
    }
  }
}
