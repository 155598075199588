.field-name-field-employee-job {
  @extend %h4-title;
  margin-bottom: 10px;
}

.node-employee {
  float: left;
  width: 100%;

  .field-type-image {
    display: block;
    margin: 0;
    img {
      display: block;
      margin: 0;
    }
  }

  &.node-teaser {
    margin-bottom: 20px;

    .group-left {
      float: left;
      width: 130px;
      min-height: 130px;
    }
    .group-right {
      float: left;
      width: calc(100% - 130px);
      padding: 20px;
      text-align: center;
    }
    .group-footer {
      float: left;
      width: 100%;
      @extend %h2-black;
      @include font-size(14, 30);
      text-transform: uppercase;
    }
  }
}
