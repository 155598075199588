// -- COLOURS -- //

// ** MAIN
$black:             #000000;
$white:             #FFFFFF;

// ** TABS
$tabs-background:   rgba($black, 0.8);
$tabs-text:         $white;
$tabshover:         lighten($tabs-background, 10%);

// ** ALERTS
$error:             #E74C3C;
$error-dark:        #C0392B;
$status:            #3FC380;
$status-dark:       #00B16A;
$warning:           #EB9532;
$warning-dark:      #F2784B;

// ** CUSTOM SITE
$coil:              #2C2425;
//$dark-grey-bg:      #2F2628;
$light-grey:        #F4F1F1;

//$hairline-grey:     #C26E6E;
//$hairline-grey:     rgba($coil, .25);
$yellow:            #FFC200;
$yellow-light:      #FFD962;

$button-colour:     $yellow;
$placeholder:       $coil;
$border-color:      rgba($coil, .25);

$disabled:          #C2BEBE;
