.vacancy_feature-vacancy_feature_vac_sector_block {
  float: left;
  width: 100%;
  @extend .agro_glue-homepage_izw;
  color: $white;
  z-index: 20;
  .pane-title,
  .content-wrapper {
    background: $coil;
  }

  h2.pane-title {
    color: $white;
    float: left;
    width: 100%;
    padding: 15px $gutter 0;
    @extend %h2-title-wide;
    text-transform: uppercase;
    text-align: center;
    margin: 0;
    padding-bottom: 15px;
  }

  ul {
    li {
      a {
        color: $white;
        &:hover {
          border-bottom: 1px solid $white;
        }
      }
    }
  }
  ul.links {
    li {
      padding-right: 0;
      width: 100%;
      a {
        &:hover {
          border-bottom: 0;
        }
      }
    }
  }
}
