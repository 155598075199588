body.maintenance-page {
  @include outer-container;
  padding: 0 10px;
  background-color: $white;
  #page {
    z-index: 2;
    position: relative;
    @include break1 {
      @include span-columns(10 of 12);
      @include shift(1);
    }
    @include break2 {
      padding: 0;
      @include span-columns(6);
      @include shift(3);
    }

    .cloud {
      display: table-cell;
      vertical-align: middle;
      padding: 50px 0;
    }

    @include full-bg(transparent);
    &:before {
      @include linear-gradient(70deg, $yellow 0%, $yellow 30%, $white 30%, $white 100%);

      @include break1 {
        @include linear-gradient(70deg, $yellow 0%, $yellow 22%, $white 22%, $white 100%);
      }
      @include break2 {
        @include linear-gradient(70deg, $yellow 0%, $yellow 30%, $white 30%, $white 100%);
      }
    }
    display: table !important;
    height: 100vh;
    min-height: 100vh;
  }
  #logo {
    float: left;
    width: 100%;
    margin-bottom: 45px;

    @include span-columns(8 of 10);
    @include shift(1 of 10);
    @include break1 {
      @include span-columns(4 of 10);
      @include shift(3 of 10);
    }
    @include break2 {
      @include span-columns(4 of 6);
      @include shift(1 of 6);
    }
    img {
      max-width: 100%;
      width: 100%;
      height: auto;
    }
  }
  h2 {
    float: left;
    width: 100%;
    @include font-size(25);
    text-transform: inherit;
    @include break1 {
      padding-left: 30px;
    }
    span {
      @extend %h1-alt;
      display: block;
      margin-left: 0;
      text-shadow: -2px -2px 0 $white, 2px -2px 0 $white, -2px 2px 0 $white, 2px 2px 0 $white;
    }
  }

  .black-box {
    float: left;
    width: 100%;
    background-color: $coil;
    color: $white;
    padding: 30px 100px 5px 30px;
    @include font-size(12);
    margin-bottom: 60px;
    position: relative;

    &:before {
      content: '';
      display: block;
      position: absolute;
      right: 30px;
      top: -6px;
      height: 53px;
      width: 33px;

      background: {
        size: contain;
        position: center;
        repeat: no-repeat;
        image: url('../images/werkers-tag.svg');
      }
    }

    p {
      margin: 0 0 20px 0;
      //&:last-child {
      //  margin-bottom: 0;
      //}
    }
    .social {
      line-height: 1;
      margin-top: 20px;
    }
  }

  #footer-wrapper {
    text-align: center;
    h3,
    p {
      margin: 0;
    }
    a {
      display: block;
      @include break1 {
        display: inline;
      }

    }
    span {
      display: none;
      @include break1 {
        display: inline;
      }
    }
  }
}
