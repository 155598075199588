.view-aanbod-detail {
  @include full-bg($white);
  float: left;
  width: 100%;
  .view-content {
    // flexbox baby!
    float: left;
    width: 100%;
    @include display-flex();
    @include flex-wrap(wrap);
  }
  .node {
    width: 100%;
    float: left;
    margin-bottom: 20px;
    @include break1 {
      @include span-columns(6);
      @include omega(2n);
      @include display-flex();
      @include flex-direction(column);
    }
    @include break2 {
      &:nth-child(2n + 1) {
        clear: none;
      }
      &:nth-child(2n + 2) {
        margin-right: flex-gutter();
      }
      @include span-columns(4);
      @include omega(3n);
      @include display-flex();
      @include flex-direction(column);
    }
  }
}
