// -- TYPO SETTINGS -- //

// ** BASE
// -- Set a base font-size (dont use px!) & font-family
$base-font-size: 12;
$base-font-family: 'Montserrat', 'Arial', sans-serif;

$letter-spacing-small: .05em;
$letter-spacing-small-hover: .027em;
$letter-spacing-big: .1em;

// ** HEADINGS
// -- Set your h1, h2, ... size (don't use px!) and colour

$h1-size: 17;
$h1-colour: $coil;
$h2-size: 14;
$h2-colour: $coil;
$h3-size: 13;
$h3-colour: $coil;
$h4-size: 12;
$h4-colour: $coil;
$h5-size: 10;
$h5-colour: $coil;
$h6-size: 10;
$h6-colour: $coil;

$h1-size-alt: 50px;
$h1-color-alt: $yellow;
$h2-size-alt: 15;
