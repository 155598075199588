// amount of rows, change if they want more or less days displayed
$days: 6;
#edit-appointment {
  background: $coil;
  margin-bottom: 30px;
  //color: $white;
  @include break1 {
    padding: 0;
    margin: 0 0 30px;
  }
  > .fieldset-wrapper {
    @include break1 {
      @include span-columns(10 of 12);
      @include shift(1);
    }
    @include break2 {
      @include span-columns(6 of 8);
      @include shift(1);
    }
  }

  span {
    @include font-size(14, 40);
    @include break1 {
      @include font-size(11, 40);
    }
  }
  #edit-timeslots {
    border-bottom: 1px solid $border-color;
    background: $coil;
    > legend {
      color: $white;
      text-transform: uppercase;
      text-align: center;
    }
  }
  #edit-slots {
    background: $white;
    float: left;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid $border-color;
    @include break1 {
      width: #{1 / ($days + 1) * 100%};
      margin-top: 41px;
      border-bottom: 0;
    }
    .timeslot {
      display: block;
      float: left;
      width: 50%;
      border-right: 1px solid $border-color;
      white-space: nowrap;
      @include break1 {
        width: 100%;
        border-right: none;
        border-bottom: 1px solid $border-color;
      }

      .time {
        &:first-child {
          &:after {
            content: '-';
            display: inline-block;
            margin: 0 7px;
            @include break2 {
              margin: 0 3px;
            }
          }
        }
        &:last-child {
          &:after {
            content: none;
          }
        }
      }
    }
  }
  fieldset {
    margin: 0;
    padding: 0;
    &.day {
      background: $white;
      float: left;
      width: 100%;
      @include break1 {
        width: #{1 / ($days + 1) * 100%};
      }
      legend {
        margin: 0;
        float: left;
        width: 100%;
        color: $coil;
        background-color: $white;
        text-align: center;
        border-bottom: 1px solid $border-color;
        padding: 0;
        @include break1 {
          border-right: 1px solid $border-color;
        }

        span.fieldset-legend {
          text-transform: uppercase;
        }

        .month {
          text-transform: lowercase;
        }
      }
      > .fieldset-wrapper {
        float: left;
        width: 100%;
      }
    }
  }

  .form-item {
    width: 50%;
    float: left;
    border-right: 1px solid $border-color;

    @include break1 {
      width: 100%;
    }
    &:last-child {
      border-right: 0;
      @include break1 {
        border-right: 1px solid $border-color;
      }
    }
  }

  input[type='checkbox'] {
    display: none;
    &:disabled {
      + label {
        background: $disabled;
        cursor: not-allowed;
        &:before {
          content: none !important;
        }
      }
    }
    &:checked {
      + label {
        background: $yellow;
        @include font-awesome($check, $white, 16px);

      }
    }
  }
  label {
    position: relative;
    cursor: pointer;
    display: block;
    background: $white;
    min-height: 40px;
    text-indent: -99999px;
    margin: 0;
    border-bottom: 1px solid $border-color;
    @include font-awesome($check, $disabled, 16px);
    font-size: 1px;

    &:before {
      position: absolute;
      text-indent: 0;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: 0;
      width: 100%;
      height: 100%;
      text-align: center;
      line-height: 40px;
      @include break2 {
        content: none;
      }
    }
    &:hover {
      @include break2 {
        &:before {
          content: $check;
        }

      }
    }
  }
}
