.paragraphs-item-paragraph-cta {
  margin-bottom: 20px;
  padding: 20px 20px 0;
  background: $white;
  border-bottom: 1px solid $border-color;
  text-align: center;

  .field-name-field-paragraph-title {
    text-align: center;
    h2 {
      margin-top: 0;
    }
  }
  .field-name-field-paragraph-body {
    text-align: left;
    margin-bottom: 20px;
  }

  .field-type-link-field {
    @extend .field-name-vacancy-feature-jobapply;
    margin-top: 0;
  }
}
