.slick {
  iframe {
    width: 100%;
  }
}

.slick--slider {
  margin-bottom: 10px;
  border-bottom: 1px solid $border-color;
  img {
    width: 100%;
    height: auto;
  }
  .media--video {
    @include ratio('iframe',77, 45);
    max-width: 770px;
  }
}

.slick--carousel {
  position: relative;
  margin-bottom: 10px;
}

.slick-list {
  .slick--carousel & {
    overflow: hidden;
    margin: 0 50px;
    padding: 10px 0;

    .slick__slide {
      @include break1max {
        width: 50% !important;
        padding: 0 10px 6px 0;
      }
      img {
        margin: 0 auto;
      }
    }
  }

}
.slick__arrow {
  .slick-arrow {
    overflow: hidden;
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    @include square(40px);
    margin: auto;
    color: transparent;

    &:before {
      display: block;
      position: relative;
      height: 30px;
      line-height: 30px;
      text-align: center;
      width: 100%;
    }
  }

  .slick-prev {
    left: 0;
    @include font-awesome($angle-left, $white, 50px);

  }
  .slick-next {
    right: 0;
    @include font-awesome($angle-right, $white, 50px);
  }
}
