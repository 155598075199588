// fields

.field-name-field-testimonial-photo {
  position: relative;
  display: block;
  float: left;
  width: 100%;
  margin: 0 auto;
  margin-bottom: -40px;
  z-index: 1;
  img {
    display: block;
    margin: 0 auto;
    max-width: 130px;
    width: 100%;
  }
}
.field-name-field-testimonial-text {
  position: relative;
  background: $white;
  text-align: center;

  float: left;
  width: 100%;

  padding: 60px 30px 25px;

  .page-home & {
    background: $light-grey;
  }
  &:before {
    @extend .node-office:before;
    // place it in the right middle of the left space.
    right: calc((((100% - 130px) / 2) - 33px) / 2);
  }
}

.field-name-field-testimonial-case {
  @extend .field-name-node-link;
}

.node-testimonial {
  float: left;
  width: 100%;
  .group-title {
    float: left;
    width: 100%;
    text-align: center;
    background: $coil;
    color: $white;
    border-bottom: 1px solid $border-color;
    > h2 {
      @include font-size(20);
      color: $white;
      margin-top: 5px;
      margin-bottom: 0;
    }
    .field-name-field-testimonial-location {
      margin-bottom: 5px;
    }
  }
  &.node-teaser {
    background: transparent;
    border-bottom: none;
  }
}
