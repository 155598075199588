.page-over-wie-is-wie {
  .who-row {
    margin-bottom: 40px;
    float: left;
    width: 100%;

    .node-office,
    .view-id-employees {
      @include break1 {
        @include span-columns(6);
      }
    }
    .node-office {
      @include break1 {
        margin-right: 0;
        float: right;
        margin-left: flex-gutter();

      }
      @include break2 {
        @include span-columns(4);
        margin-right: 0;
        float: right;
      }
    }
    .view-id-employees {
      @include break2 {
        @include span-columns(8);

        .node-employee {
          @include span-columns(4 of 8);
          @include omega(2n);
        }
      }
    }
  }
  .bean--cta_block {
    @include break2 {
      @include span-columns(8);
      @include shift(2);
    }
    .field-name-field-cta-block-link {
      a {
        @include break2 {
          @include span-columns(4 of 8);
          @include shift(2 of 8);
        }
      }
    }
  }
}
