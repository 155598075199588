.field-name-field-multi-bean-block {
  .field-item {
    margin-bottom: 30px;
    @include break1 {
      @include span-columns(6);
      @include omega(2n);
    }
    @include break2 {
      @include span-columns(4);
      &:nth-child(2n + 1) {
        clear: none;
      }
      &:nth-child(2n + 2) {
        margin-right: flex-gutter();
      }
      @include omega(3n);
    }
  }
  .entity-field-collection-item {
    @extend .node-teaser;
    > div {
      margin: 0 25px 20px;
    }
    .field-type-image {
      margin: 0 0 17px;
    }

    .field-name-field-multi-bean-block-title {
      h3 {
        @extend %h2-bigger-underline;
        @include font-size(24);
      }
    }
    .field-name-field-multi-bean-block-link {
      margin-bottom: -10px;
      a {
        @include button;
        width: 100%;
        text-align: center;
      }
    }
  }
}

// Homepage overrides
.region-home-top {
  @include full-bg($white);
}
.bean-multi-bean-blocks.view-mode--homepage-ctas {
  margin: 60px 0 50px;

  .field-name-field-multi-bean-block > .field-items {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 -10px;
    > .field-item {
      width: 100%;
      margin: 0 10px 40px;
      @include break1 {
        width: calc((100% - 40px) / 2);
      }
      @include break2 {
        width: calc((100% - 60px) / 3);
        margin: 0 10px 20px;
      }
    }
  }
}
.field-collection-item-field-multi-bean-block.view-mode--homepage-ctas {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: $light-grey;

  .field-name-field-multi-bean-block-title {
    padding: 40px 0 10px;
    h3 {
      font-weight: 700;
      font-size: 36px;
      text-align: center;
      padding-bottom: 20px;
      &:after {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  .field-name-field-multi-bean-block-text {
    margin-bottom: 40px;
  }
  .field-name-field-multi-bean-block-image {
    margin: auto 0 0;
    img {
      display: block;
    }
  }
  .field-name-field-multi-bean-block-link {
    text-align: center;
    position: absolute;
    bottom: 0;
    margin: 0;
    width: 100%;
    transform: translateY(50%);
    a {
      width: 60%;
      padding: 12px 20px;
    }
  }
}
