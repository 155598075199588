.apply-for-vacancy-page {
  .panel-pane {
    &.apply-form {
      padding: 0 50px;
      background: $white;
      border-bottom: 1px solid $border-color;
    }
  }
  .panel-col-last {
    .panel-pane {
      &:first-child {
        background: $white;
        padding: 55px 20px 20px;
        position: relative;
        .pane-title {
          text-align: center;
        }

        &:before {
          content: '';
          display: block;
          position: absolute;
          right: 35px;
          top: -6px;
          height: 53px;
          width: 33px;

          background: {
            size: contain;
            position: center;
            repeat: no-repeat;
            image: url('../images/werkers-tag.svg');
          }
        }
      }
    }
  }
}

// vacature form
.ui-icon-circle-triangle-e {
  @include font-awesome($angle-right, $black, 14px);
}
.ui-icon-circle-triangle-w {
  @include font-awesome($angle-left, $black, 14px);
}
.ui-icon-circle-triangle-e,
.ui-icon-circle-triangle-w {
  font-size: 0;
  &:hover {
    cursor: pointer;
  }
  &:before {
    margin-top: -3px;
    font-weight: bold;
  }
}

.vacancy-feature-apply-form {
  float: left;
  width: 100%;
  margin-bottom: 20px;
  .form-wrapper {
    float: left;
    width: 100%;
  }
  #edit-personal-info {
    background: $white;
    border-bottom: 1px solid $border-color;
    padding: 0 50px;
    margin-bottom: 20px;
    .form-item {
      margin-bottom: 10px;
      &.form-item-first-name {
        clear: both;
      }
    }

    .form-item-title {
      .required {
        display: none;
      }
    }
    .form-radios {
      float: left;
      width: 100%;
    }

    .form-type-textfield {
      @include break1 {
        float: left;
        width: calc(#{50% - flex-gutter()});
        margin-right: 2 * flex-gutter();
        &:nth-child(2n + 1) {
          margin-right: 0;
        }
      }
    }

    .container-inline-date {
      .form-type-date-popup {
        width: 100%;
      }
      .date-padding {
        float: none;
      }
      .form-item input {
        width: 100%;
      }
      small {
        position: absolute;
        top: 100%;
        margin: 0 0 0 20px;
        width: 100%;
      }
    }
    .form-item-birth-date-date {
      margin-bottom: 16px;
      & > label {
        display: none;
      }
    }
  }

  .form-actions {
    text-align: center;
    margin: 0 auto;
    float: left;
    width: 100%;

    input[type='submit'] {
      width: 100%;
      @include break1 {
        @include span-columns(6 of 10);
        @include shift(2 of 10);
      }
      @include break2 {
        @include span-columns(4 of 8);
        @include shift(2 of 8);
      }
    }
  }
}
