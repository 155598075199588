@font-face {
	font-family: "dropsolid-base-icons";
	src: url('../fonts/dropsolid-base-icons/dropsolid-base-icons.eot');
	src: url('../fonts/dropsolid-base-icons/dropsolid-base-icons.eot?#iefix') format('eot'),
		url('../fonts/dropsolid-base-icons/dropsolid-base-icons.woff') format('woff'),
		url('../fonts/dropsolid-base-icons/dropsolid-base-icons.ttf') format('truetype'),
		url('../fonts/dropsolid-base-icons/dropsolid-base-icons.svg#dropsolid-base-icons') format('svg');
}

%icon {
	font-family: "dropsolid-base-icons";
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	// speak: none; // only necessary if not using the private unicode range (firstGlyph option)
	text-decoration: none;
	text-transform: none;
}

@function icon-char($filename) {
	$char: "";

	@if $filename == globe {
		$char: "\E001";
	}
	@if $filename == test {
		$char: "\E002";
	}

	@return $char;
}

@mixin icon($filename, $insert: before) {
	&:#{$insert} {
		@extend %icon;
		content: icon-char($filename);
	}
}

.icon-globe {
	@include icon(globe);
}
.icon-test {
	@include icon(test);
}
