#overlay-content {
  .drupal-messages,
  .header-wrapper,
  #breadcrumb,
  footer {
    display: none !important;
  }
}
//#overlay-titlebar {
//  display: none !important;
//}

#overlay-title {
  color: $coil;
  margin-bottom: 20px;
}

.overlay-close {
  text-indent: -99999px;
}
//span.close-overlay {
//  display: none;
//}

.overlay-active {
  top: 80px;
}
