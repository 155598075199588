.page-arbeidsveiligheid {
  .panel-col-top {

    #edit-zoekwoord-wrapper {

    }
    @include break2 {
      .agro_glue-arbeidsveiligheid_txt {
        @include span-columns(8);
      }
      .bean--cta_block {
        @include span-columns(4);
        @include omega;
      }
    }
  }
  .downloads-panel_pane_1 {
    float: left;
    width: 100%;
  }
}

#views-exposed-form-downloads-panel-pane-1-mefibs-form-right {
  @extend .views--exp-custom_faq-faq_search;

  .views-submit-button {
    bottom: 7px;
    top: initial !important;
  }
  .ajax-progress-throbber {
    position: absolute;
    right: 28px;
    top: 4px;
  }

  label {
    float: left;
    width: 100%;
    @extend h2;
    text-align: center;
  }
}
