// Fields
.field-name-field-office-photo {
  margin: 0 -30px;

  img {
    margin: 0;
    display: block;
  }
}

.field-name-agro-glue-node-address {
  margin-bottom: 20px;
}

.field-name-office-feature-gmap-link {
  margin-bottom: 30px;
  a {
    border-bottom: 1px solid $border-color;
    display: inline-block;
  }
}
.field-name-field-office-phone {
  font-weight: bold;
}

.field-name-field-office-hours,
.field-name-office-feature-openinghours {
  margin-bottom: 30px;

  .head-office-text {
    text-align: left;
  }
}
.field-name-office-feature-contact-link {
  margin-bottom: 20px;
  text-align: center;
  a {
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: $letter-spacing-small;
    padding-bottom: 2px;
    margin-bottom: 20px;

    border-bottom: 2px solid $coil;
    &:hover {
      opacity: .7;
    }
  }

}
.field-name-field-office-mail {
  margin-bottom: 30px;
}
.field-name-office-feature-who-link {
  padding: 0 25px;
  margin: 0 -30px;
  a {
    @include button;
    display: block;
    margin-bottom: -10px;
  }
}

// view mode
.node-office {
  float: left;
  width: 100%;
  position: relative;
  background: $white;
  text-align: center;
  padding: 0 30px;

  // tag icon stuff
  &:before {
    content: '';
    display: block;
    position: absolute;
    right: 35px;
    top: -6px;
    height: 53px;
    width: 33px;

    background: {
      size: contain;
      position: center;
      repeat: no-repeat;
      image: url('../images/werkers-tag.svg');
    }
  }
  > h2 {
    margin: 17px 35px 10px;
    letter-spacing: $letter-spacing-big;
  }

  &.node-teaser {
    margin-bottom: 30px;
    border-bottom: none; // override default border

    .field-name-office-feature-who-link {
      position: absolute;
      bottom: 0;
      width: 100%;
    }
  }

  &.view-mode-horizontal_teaser {
    padding: 0;
    margin: 20px 0 35px;
    overflow: hidden;
    &:before {
      content: none;
    }
    .field-name-field-office-photo {
      margin: 0;
      img {
        width: 100%;
        height: auto;
        @include break1 {
          width: auto;
          height: 100%;
        }
      }
    }
    .group-left,
    .group-right {
      width: 100%;
      float: left;
    }
    .group-left {
      @include break1 {
        width: flex-grid(6, 10) + flex-gutter(10);
      }
      @include break2 {
        width: flex-grid(3, 6) + flex-gutter(6);
      }
    }
    .group-right {
      overflow: hidden;
      @include break1 {
        @include span-columns(4 of 10);
        @include omega;
      }
      @include break2 {
        @include span-columns(3 of 6);
        @include omega;
      }
    }
  }
}
