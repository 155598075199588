.bb_social_share-bb_social_share {
  padding: 20px;
  background: $white;
  border-bottom: 1px solid $border-color;
  text-align: center;

  h2 {
    margin-top: 0;
    margin-bottom: 20px;
  }

  .bb-social-share--links {
    display: flex;
    justify-content: center;
  }
  .bb-social-share--link {
    margin: 0 2px;
    position: relative;
    width: 42px;
    height: 42px;

    a {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #848484;
      transition: top 0.2s;
      &:hover {
        top: -6px;
        transition: top 0.2s;
      }

      &.bb-social-facebook {
        background-color: #3B5998;
      }
      &.bb-social-twitter {
        background-color: #1DA1F2;
      }
      &.bb-social-linkedin {
        background-color: #0077B5;
      }
      &.bb-social-print {
        background-color: #738A8D;
      }
    }
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
