// fields
.field-name-field-cta-block-description {
  margin-bottom: 20px;
  p:last-child {
    margin-bottom: 0;
  }
}

.field-name-field-cta-block-link {
  a {
    @include button;
    margin-bottom: 20px;
    width: 100%;
  }
}

.bean--cta_block {
  padding: 20px 20px 0;
  background: $white;
  border-bottom: 1px solid $border-color;
  text-align: center;

  h2 {
    margin-top: 0;
  }
  .contact .faq &,
  .panel-2col-stacked .panel-col-first & {
    > * {
      @include break1 {
        @include span-columns(10);
        @include shift(1);
      }
      @include break2 {
        @include span-columns(6 of 8);
        @include shift(1 of 8);
      }
    }
    .field-name-field-cta-block-description {
      text-align: left;
    }
    .field-name-field-cta-block-link {
      a {
        @include break2 {
          @include span-columns(4 of 8);
          @include shift(2 of 8);
        }
      }
    }
  }

  &.bean-contact-default {
    background: $coil;
    color: $white;
    h2 {
      color: $white;
    }
  }
}
