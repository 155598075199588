//$special-gutter: calc(#{flex-gutter(6)} + #{$gutter});

.page-home {
  // pane-titles
  .pane-title {
    @include font-size(30);
    text-transform: initial;
    float: left;
    width: 100%;

    .title-capital {
      display: block;
      @extend %h1-alt;
      margin-left: 0;
    }
  }
  .panels-flexible-row,
  .panels-flexible-region-inside {
    padding: 0;
  }

  .row-fifty-fifty {
    .panel-pane {
      margin-bottom: 0;
    }
    @include break2 {
      > .inside {
        @include display-flex;
      }
      .panels-flexible-region {
        @include fill-parent();
        width: 1/2 * 100%;
        max-width: 1/2 * 100%;
        @include flex-basis(1/2 * 100%);
        @include flex-shrink();
        @include omega(2n);
      }
    }
  }
  .row-fifty-left {
    &:before {
      //@include break2 {
      //  margin-left: 0;
      //  margin-right: $absolute-left;
      //}
    }
    @include break2 {
      padding-right: flex-gutter() / 2;
    }
    .panels-flexible-region-inside {
      > div {
        @include break2 {
          padding-right: flex-grid(1,6);
        }
      }
    }
  }
  .row-fifty-right {
    @include full-bg($light-grey);
    &:before {
      @include break2 {
        margin-left: 0;
        margin-right: $absolute-left;
      }
    }
    @include break2 {
      padding-left: flex-gutter() / 2;
    }
    .panels-flexible-region-inside {
      > div {
        @include break2 {
          padding-left: flex-grid(1,6);
        }
      }
    }
  }

  .row-shift {
    .panel-pane {
      margin-bottom: 0;
    }
    > .inside {
      @include break2 {
        @include display-flex;
        @include flex-direction(row-reverse);
      }
    }
    .panels-flexible-region {
      position: relative;
      padding-top: 30px;
      padding-bottom: 35px;
      @include break2 {
        @include fill-parent();
        width: 1/2 * 100%;
        max-width: 1/2 * 100%;
        @include flex-basis(1/2 * 100%);
        @include flex-shrink();
        @include omega(2n);
        float: right;
      }
    }
  }
  .row-shift-left {
    @include full-bg($coil);
    color: $white;
    text-align: right;
    @include break2 {
      padding-right: flex-grid(1) + (flex-gutter() /2);
    }

    .pane-title {
      padding-right: $gutter;
      color: $yellow;
      margin-bottom: 25px;
    }
    p {
      float: left;
      width: 100%;
    }
  }
  .row-shift-right {
    @include full-bg($white);
    z-index: 10;
    @include break2 {
      padding-left: flex-grid(1) + (flex-gutter() /2);
    }

    &:before {
      @include break2 {
        margin-left: 0;
        margin-right: $absolute-left;
      }
    }

    .inside {
      @include break2 {
        @include span-columns(5 of 6);
      }
    }
  }

  .row-cases {
    @include full-bg($white);
  }
  .row-testimonials {
    padding-top: 75px;
    padding-bottom: 30px;
  }

  // block specific
  .vacancies-panel_pane_2 {
    float: left;
    width: 100%;
    @include full-bg($light-grey);
  }
  .vacancies-panel_pane_2,
  .cases-panel_pane_2,
  .news-panel_pane_2 {
    .pane-title {
      @extend %h2-title-wide;
      text-align: center;
      text-transform: uppercase;
      padding: 15px 0;
    }
  }

  .home-vacancy-region {
    .panels-flexible-region-inside {
      height: 100%;
    }
  }
  .vacancy_feature-vacancy_feature_search_block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 40px 0;
    .pane-title {
      width: 100%;
      font-weight: 700;
      font-size: 36px;
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 40px;
      span {
        &:after {
          right: 0;
        }
      }
    }

    form {
      float: left;
      width: 100%;
      input {
        padding: 4px 20px;
        height: auto;
      }
    }

    .form-actions {
      float: right;
      width: 100%;
      input {
        width: 100%;
        padding: 10px 20px;
      }
      @include break2 {
        @include span-columns(2 of 4);
        margin-bottom: 25px;
      }
    }

    .form-item-location-search,
    .form-actions {
      @include break1 {
        @include span-columns(6);
      }
      @include break2 {

      }
    }
    .form-item-location-search {
      @include break2 {
        width: flex-grid(2, 4) + flex-gutter();
        float: left;
        //@include span-columns()
      }
    }
  }

  .agro_glue-homepage_allnews_button {
    margin-top: 10px;
    margin-bottom: 20px;

    > a {
      @include break1 {
        @include span-columns(6);
        @include shift(3);
      }
      @include break2 {
        @include span-columns(4);
        @include shift(4);
      }
    }
  }
  @include break2 {
    // left
    .vacancy_feature-vacancy_feature_search_block,
    .vacancy_feature-vacancy_feature_vac_sector_block {
      padding-left: calc(#{flex-gutter(5)} + #{$gutter});
    }
    .agro_glue-homepage_izw {
      padding-right: calc(#{flex-gutter(5)} + #{$gutter});
    }
  }

  // equal height stuff
  .intro-eh,
  .izw-eh,
  .vacancies-eh {
    float: left;
    width: 100%;
  }
}
