.page-vacatures {
  .vacancies-panel_pane_1 {
    float: left;
    width: 100%;
    + .bean--cta_block {
      margin-top: 0;
    }
  }
  .panel-col-first {
    .bean--cta_block {
      margin-top: 20px;
    }
  }
  .views-exposed-form {
    background-color: $coil;
    margin-bottom: 10px;
    @include break1 {
      padding: 24px 44px 0;

    }
    // inside selector to override views css
    .views-exposed-widget {
      @include break1 {
        margin: 0 6px;
        width: calc(50% - 12px);
        float: left;
      }
      .form-item {
        padding: 10px 10px 0;
        @include break1 {
          padding: 0 0 15px;
        }
      }
      &#edit-distance-wrapper {
        @include break1 {
          .form-item {
            margin: 0 6px;
            float: left;
          }
          .form-item-distance-postal-code {
            margin-left: 0;
            width: calc(60% - 6px);
          }
          .form-item-distance-search-distance {
            margin-right: 0;
            width: calc(40% - 6px);
          }
        }
      }
    }
    .views-submit-button {
      float: none;

      margin: auto;
      margin-bottom: -10px;
      @include break1 {
        width: 50%;
      }
      input {
        width: 100%;
      }
    }
  }
}
