.tooltip {
  z-index: 10;
  position: absolute;
  background: $coil;
  min-width: 100%;
  padding: 13px;
  display: none;
  cursor: pointer;

  @include font-size(10, 18);

  @include break2 {
    left: 100%;
    bottom: -10px;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    right: 10px;
    top: -9px;

    width: 0;
    height: 0;
    border-style: solid;

    border-width: 0 12.5px 10px 12.5px;
    border-color: transparent transparent $coil transparent;
    @include break1 {
      right: 27px;
    }

    @include break2 {
      top: auto;
      right: auto;
      bottom: 20px;
      left: -10px;
      border-width: 12.5px 10px 12.5px 0;
      border-color: transparent $coil transparent transparent;
    }
  }

  * {
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: $letter-spacing-small;
    color: $white;
  }
  p {
    margin: 0;
  }
  a {
    text-decoration: underline;
    margin-bottom: 0 !important;
    &:hover {
      color: $white;
      opacity: .8;
    }
  }

  a:hover ~ & {
    @include break2 {
      display: block;
    }
  }
  .toggle-tooltip:hover + & {
    display: block;
  }
}
.toggle-tooltip {
  margin-right: 13px;
  float: right;
  background: $coil;
  @include font-size(14, 16);
  @include square(16px);
  font-weight: bold;
  border-radius: 8px;
  color: $white;
  text-align: center;
  cursor: pointer;
  @include break2 {
    display: none !important;
  }
}
