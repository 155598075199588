.node-article {
  padding-bottom: 10px;
  > h3 {
    @extend %h2-bigger-underline;
  }
  .field-name-post-date {
    @include font-size(10);
  }

  &.view-mode-teaser {
    h3,
    > div {
      margin: 0 25px 10px;
    }

    .field-type-image {
      margin: 0 0 17px;
    }
  }

  // page detail
  &.view-mode-full {
    padding-bottom: 0;
    //background: $white;

    .field-name-post-date {
      background: $white;
      padding-top: 10px;
      padding-left: 30px;
      padding-bottom: 15px;
    }
    .field-name-field-image {
      border-bottom: 1px solid $border-color;
      margin-bottom: 15px;
      img {
        margin: 0;
        display: block;
        width: 100%;
        height: auto;
      }
    }
    .field-name-body {
      background: $white;
    }
  }
}
