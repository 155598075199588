// -- ADMIN -- //

// ** MESSAGES
// --  Do this not to annoy back-end devs ;)
body .admin-menu .drupal-messages {
  position: relative;
  div.messages {
    margin-bottom: 0px;
  }
}

.drupal-messages {
  overflow: hidden;
}
// -- Main settings
div.messages {
  background-size: 30px 30px;
  background-position: 10px 10px; /* LTR */
  background-repeat: no-repeat;
  margin-top: 0px;
  padding: 15px 30px 15px 60px; /* LTR */
  position: relative;
  clear: both;
  @include transform(translate(0px, 0px));
  @include transition(transform 1s $timing, -moz-transform 1s linear);
  .close {
    position: absolute;
    right: 5px;
    top: 15px;
    display: block;
    @include square(40px);
    text-indent: -9999px;
    border: none !important;
    color: $white;
    outline: none;
    &:after {
      content: '+';
      text-indent: 0px;
      top: 0%;
      left: 0%;
      position: absolute;
      @include transition(opacity $duration $timing);
      @include transform(rotate(45deg));
      font-size: 50px;
      @include square(40px);
    }
    &:hover:after {
      opacity: 0.7;
    }
  }
  ul {
    margin: 0 0 0 1em; /* LTR */
    padding: 0;
    li {
      list-style-image: none;
    }
  }
  &.closing {
    @include transform(translate(-100%, 0px));
    @include transition(transform 1s $timing, -moz-transform 1s linear);
  }
  &.closed {
    display: none;
  }
}
div.status,
.ok,
div.warning,
.warning,
div.error,
.error {
  color: $white;
  border-radius: 5px;
}
div.status,
.ok {
  a {
    color: $white;
  }
}
div.status {
  background-image: url('../images/ok.png');
  background-position: 1% 48%;
  border: 0;
  a {
    color: darken($status-dark, 10%);
    &:hover {
      color: $status-dark;
    }
  }
}
div.status,
table tr.ok {
  background-color: $status;
  border: 1px solid $status-dark;
}
div.warning {
  background-image: url('../images/warning.png');
  background-position: 1% 48%;
  border: 0;
  a {
    color: darken($warning-dark, 10%);
    &:hover {
      color: $warning-dark;
    }
  }
}
div.warning,
table tr.warning {
  background-color: $warning;
  border: 1px solid $warning-dark;
}
div.error {
  background-image: url('../images/error.png');
  background-position: 1% 48%;
  border: 0;
  a {
    color: darken($error-dark, 10%);
    &:hover {
      color: $error-dark;
    }
  }
}
div.error,
table tr.error {
  background-color: $error;
  border: 1px solid $error-dark;
}

// ** TABS
nav.tabs {
  width: 100%;
  background: $tabs-background;
  @include clearfix;
  ul {
    float: right;
    li {
      float: left;
      padding: 10px;
      a {
        color: $tabs-text;
        text-decoration: none;
      }
    }
  }
}

// ** FIXES
// -- Contextual links
.contextual-links-region {
  &:hover {
    > .contextual-links-wrapper {
      .contextual-links-trigger {
        display: block;
      }
    }
  }
}
ul.contextual-links {
  height: auto;
  width: auto;
}
// -- Padding body > wysiwyg
body {
  .cke_editor,
  .cke_editable {
    padding-top: 0 !important;
  }
}
// -- Admin toolbar
#admin-toolbar * {
  float: none;
  box-sizing: content-box;
  -moz-box-sizing: content-box;
}
// -- Hide invisible elements
.element-invisible {
  display: none;
  visibility: hidden;
}
// -- Devel fix
div.krumo-root {
  color: $black;
  * {
    float: none;
  }
  a.krumo-name {
    color: #404000;
  }
}
