.agro_glue-homepage_about {
  // tag icon stuff
  position: relative;
  &:before {
    content: '';
    display: block;
    position: absolute;
    right: 35px;
    top: -36px;
    height: 53px;
    width: 33px;

    background: {
      size: contain;
      position: center;
      repeat: no-repeat;
      image: url('../images/werkers-tag.svg');
    }
  }
  > * {
    padding-left: $gutter;
  }
  p {
    text-align: justify;
  }
  .pane-title {
    @extend %h2-bigger-underline;
    padding-left: 0;
    margin-bottom: 30px;
    text-align: left;
  }
  @extend .field-name-node-link;
}
