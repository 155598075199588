.translate-select {
  float: left;
  width: 100%;
  background: $white;
  border-bottom: 1px solid $border-color;
  text-align: center;
  margin-bottom: 20px;

  .pane-title {
    float: left;
    width: 100%;
  }
}

#google_translate_element {
  padding: 0 20px 20px;
  float: left;
  width: 100%;
  .goog-te-gadget-simple {
    width: 100%;
  }
}

iframe.goog-te-menu-frame {
  box-shadow: none;
}
