%faq-title {
  display: block;
  @extend %h2-title;
  text-transform: uppercase;
  font-weight: bold;
  color: $white;
  padding: 12px 40px;
  background: $coil;
}

.node-faq {
  &.node-teaser {
    border-bottom: none;
  }
}

.faq {
  > ul {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      margin-bottom: 20px;
      a {
        @extend %faq-title;
        @include transition(all $duration $timing);
        &:hover {
          background-color: $yellow;
        }
      }
    }
  }
  .faq-description {
    margin-bottom: 20px;
  }
}

.faq-question-answer {
  margin-bottom: 15px;
}
.ui-accordion-header,
.faq-question {
  padding-left: 40px;

  &:before {
    font-weight:bold;
    content: '+';
    display: block;
    float: left;
    padding-right: 7px;
  }

  &.faq-qa-visible {
    &:before {
      content: '-';
    }
  }
  &:hover {
    > span {
      border-bottom: 1px solid $border-color;
    }
  }
  > span {
    font-weight: bold;
  }
}
.ui-accordion-header {
  float: left;
  width: 100%;
  text-transform: inherit;
  @include font-size($base-font-size);
}

.view-display-id-faq_search,
.faq-dl-hide-answer {
  background: $white;
  padding-bottom: 15px;
  border-bottom: 1px solid $border-color;
}
.view-display-id-faq_search {
  padding-top: 10px;
  margin-bottom: 20px;
}

.ui-accordion-content,
.faq-answer {
  padding: 20px 70px 5px;
}
.ui-accordion-content {
  padding-bottom: 15px;
}

// page detail
.page-veelgestelde-vragen- {
  .faq-detail {
    background: $white;
    margin-bottom: 20px;
    .pane-title {
      @extend %faq-title;
      margin: 0 0 10px;
    }
  }
}
