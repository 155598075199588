@import 'neat-helpers';

// -- Set false to hide visual breakpoint indicators
$visual-indicators: true;

// -- BOURBON NEAT BREAKPOINTS -- //

// ** GRID
// -- Show grid, set to true
$visual-grid: false;
// -- Grid more settings (set with !global flag)
// $visual-grid-color -> color of the grid; default is #EEEEEE
// $visual-grid-index -> back/front; default is back
// $visual-grid-opacity -> set opacity of the grid; default is 0.4

$visual-grid-index: front;
$visual-grid-color: red;
// ** BREAKPOINTS
// -- Var settings -> uncomment what you need
// $breakpoint0a: new-breakpoint(min-width 480px 12);
$breakpoint1:	new-breakpoint(min-width 720px 12);
$breakpoint1width: 720px;
$breakpoint1max: new-breakpoint(max-width 719px 12);
// $breakpoint1a: new-breakpoint(min-width 940px 12);
$breakpoint2:	new-breakpoint(min-width 1200px 12);
$breakpoint2width: 1200px;
$breakpoint2max: new-breakpoint(max-width 1200px 12);

// -- Easy mixins
// @mixin break0a {
//   @include media($breakpoint0a) {
//     @content;
//   }
// }

@mixin tablet {
  @media (min-width: 640px) {
    @content;
  }
}

@mixin break1 {
  @media print, (min-width: #{$breakpoint1width}) {
    @content;
  }
}

@mixin break1max {
  @include media($breakpoint1max) {
    @content;
  }
}

@mixin break2 {
  @media print, screen and (min-width: #{$breakpoint2width}) {
    @content;
  }
}

@mixin break2max {
  @include media($breakpoint2max) {
    @content;
  }
}
