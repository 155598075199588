.agro_glue-homepage_allcases_button {
  margin-bottom: 20px;
  > a {
    width: 100%;
    @include break1 {
      @include span-columns(6);
      @include shift(3);
    }
    @include break2 {
      @include span-columns(4);
      @include shift(2);
    }
  }
}
