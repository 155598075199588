// -- NAVIGATION -- //

nav {
  ul {
    padding-left: 0;
    margin: 0;
  }
  li.mobile-hide-children {
    ul {
      @include break2max {
        display: none !important;
      }
    }
  }
}

// hamburger
.hamburger {
  //float: right;
  @include font-awesome($hamburger, $coil, 28px);
  position: absolute;
  top: 17px;
  right: 5px;
  z-index: 30;
  cursor: pointer;

  @include break2 {
    display: none !important;
  }
}

// nav header top
.block-menu-menu-header-top-menu,
.block-menu-menu-language-switcher {
  //float: right;
  > ul {
    margin: 0;
    li {
      a {
        @include font-size(16, 34);
        color: $white;
        display: block;
        padding: 0 10px;
        font-weight: 700;
        @include break2 {
          @include font-size(11, 25);
        }
      }
    }
  }
}
.block-menu-menu-header-top-menu {
  float: right;
  display: none;
  @include break2 {
    display: block;
  }

  > ul {
    margin: 0;
    li {
      float: left;
    }
  }
}
// mobile menu
.block-menu-menu-header-top-menu--2 {
  margin-bottom: 10px;
}

// language switcher
.block-menu-menu-language-switcher {
  //z-index: 20;
  position: relative;
  > ul {
    margin: 0;
    text-align: right;
    min-height: 0;
    max-height: 0;
    overflow: hidden;
    z-index: 20;
    clear: both;

    @include transition(min-height $duration $timing, max-height $duration $timing);

    li {
      float: left;
      background-color: $coil;
      width: 100%;
    }

    @include break2 {
      position: absolute;
      right: -10px;
      top: 100%;
      &:before {
        content: none;
      }
      // dropdown arrow
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: -1;

        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 12.5px 11px;
        border-color: transparent transparent $coil transparent;
      }
    }
  }

  input[type='checkbox'] {
    display: none;
    &:checked {
      ~ ul {
        min-height: 100%;
        max-height: 100vh;
        padding-top: 10px;

        &:after {
          top: 0px;
        }
      }
    }
    // to override default css
    + label {
      @include break2max {
        margin-bottom: 4px;
      }
    }
  }
  label {
    float: right;
    margin: 4px;
    padding: 0;
    line-height: 1;
    @include icon(globe);
    &:before {
      color: $white;
      // font-size without mixin to fix IE
      font-size: 25px;

      @include break2 {
        font-size: 17px;
      }
    }

    span {
      display: none;
    }
  }
}
// mobile
.block-menu-menu-language-switcher--2 {
  display: none !important;
}

// main menu
.block-system-main-menu {
  > ul {
    > li {
      position: relative;
      @include break2max {
        a {
          border-bottom: 3px solid transparent;
        }
      }

      ul {
        margin-left: 20px;
      }
    }
    li {
      @include break2max {
        &.active,
        &.active-trail {
          > a {
            border-bottom: 3px solid $yellow;
          }
        }
      }
    }
  }

  @include break2 {
    a {
      display: block;
      @include font-size(13, 38);
    }

    > ul {
      > li {
        float: left;
        padding: 8px 10px 12px;
        border-bottom: 1px solid transparent;
        margin-right: 32px;

        &:hover,
        &.active,
        &.active-trail {
          border-bottom: 5px solid $yellow;
          padding-bottom: 8px;
        }

        @include transition(
          border-width $duration $timing,
          padding $duration $timing
        );

        &:last-child {
          margin-right: 0;
        }

        // dropdown
        > ul {
          position: absolute;
          overflow: hidden;
          @include transition(all $duration $timing);
          min-height: 0;
          max-height: 0;
          top: calc(100% + 5px);
          left: 10px;
          margin-left: 0;

          // dropdown arrow
          &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 10px;
            z-index: -1;

            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 12.5px 11px;
            border-color: transparent transparent $white transparent;
          }

          li {
            background: $white;
            font-weight: normal;
            text-transform: initial;
            @include transition(background $duration $timing, border-color $duration $timing);
            border-bottom: 1px solid transparent;

            // dropdown arrow: make it yellow when first item is selected
            &:first-child {
              &:before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 10px;
                z-index: -1;

                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 12.5px 11px;
                border-color: transparent;
                @include transition(all $duration $timing);
              }
              &:hover {
                &:before {
                  border-color: transparent transparent $yellow;
                }
              }
            }
            &:hover {
              background: $yellow;
              font-weight: bold;
              border-color: $border-color;
              > a {
                letter-spacing: $letter-spacing-small-hover;
                @media screen and (-webkit-min-device-pixel-ratio:0) {
                  letter-spacing: $letter-spacing-small;
                }
              }
            }
            a {
              padding: 0 20px;
              @include transition(none);
            }
          }
        }

        &:hover {
          > ul {
            overflow: visible;
            min-height: 100%;
            max-height: 100vh;
            padding-top: 10px;
          }
        }
      }
    }
  }
}

// footer menu
.block-agro-glue-footer-menu-block {
  &:after {
    content: none !important;
  }
  li {
    list-style: none;
  }
  > ul {
    padding-left: 0;
    @include font-size(14, 48);
    > li {
      @include break1 {
        @include span-columns(6);
        @include omega(2n);
      }
      @include break2 {
        &:nth-child(2n + 1) {
          clear: none;
        }
        &:nth-child(2n + 2) {
          margin-right: flex-gutter(9);
        }
        @include span-columns(3 of 9);
      }

      > a {
        font-weight: bold;
      }
    }
    a {
      color: $white;
      &:hover {
        text-decoration: underline;
      }
    }
    ul {
      a {
        color: rgba($white, .7);
      }
    }
  }
}

.block-agro-glue-footer-copyright-block {
  > ul {
    list-style: none;
    margin: 0;
    padding-left: 0;

    li {
      display: inline-block;
      border-right: 1px solid $white;
      margin-right: 7px;
      padding-right: 7px;
      &:last-child {
        border-right: none;
        margin-right: 0;
        padding-right: 0;
      }
    }
    a {
      color: $white;
    }
  }
}
