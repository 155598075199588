.node-izw-detail {
  &.node-teaser {
    background: $coil;
    color: $white;
    padding: 20px 20px 0;
    margin-bottom: 20px;

    > h2 {
      @extend %h2-bigger-underline;
      color: $white;
      &:after {
        background-color: $white;
      }
    }
    .field-name-body {
      @include flex(1);
      @include flex-basis;
    }

    .field-name-node-link {
      margin-bottom: 20px;
      a {
        @include button;
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        &:before,
        &:after {
          content: none;
        }

      }
    }
  }
}

// node detail
.node-type-izw-detail {
  .slick--carousel {
    background: $coil;
  }
}
