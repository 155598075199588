.paragraphs-item-paragraph-reference-bundle {
  > .field-name-field-title {
    h2 {
      @extend %h2-black;
      fontsize: $h2-size;
      text-align: left;
      padding: 0 40px;
      margin-bottom: 20px;
    }
  }
}

.field-name-field-references {
  float: left;
  width: 100%;
  margin-bottom: 10px;

  .field-items {
    @include break1 {
      float: left;
      width: 100%;
      @include display-flex();
      @include flex-wrap(wrap);
      //@include flex-justify-content(space-around);
    }
  }
  .field-item {
    @extend .node-teaser;
    margin-bottom: 20px;
    text-align: center;
    h3 {
      margin-bottom: 10px;
    }

    .field-type-image {
      display: block;
      margin: 0;
      img {
        display: block;
        margin: 0;
      }
    }

    @include break1 {
      @include span-columns(3 of 12);
      @include omega(4n);
      @include flex-direction(column);
    }
    @include break2 {
      @include span-columns(2 of 8);
      @include flex-direction(column);
    }
  }
}
