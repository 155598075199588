// -- VARIOUS -- //

@import 'neat-helpers';

// ** TRANSITIONS
// -- Set general transition params
$duration: 0.3s;
$timing: ease-in-out;

// ** ICONS
// -- Declare font-awesome icons and use them in the font-awesome-mixin
// -- Social
$facebook: '\f09a';
$twitter: '\f099';
$linkedin: '\f0e1';
$instagram: '\f16d';
$marker: '\f041';
$dot: '\f111';
$check: '\f00c';
$search: '\f002';
// $googleplus: '\f0d5';
// $pinterest: '\f0d2';
// $email: '\f0e0';
// -- Users
// $login: '\f090';
// $logout: '\f08b';
// $user: '\f007';
// -- Navigation
$hamburger: '\f0c9';
// -- Custom
$angle-right: '\f105';
$angle-left: '\f104';
$angle-double-right: '\f101';
$angle-double-left: '\f100';
// $calendar: '\f073';
// $sort: '\f0dd';
// $comments: '\f086';
// $remove: '\f00d';
// $delete: '\f1f8';
// $edit: '\f040';
// $reply: '\f112';
// $document: '\f15b';
// $... : '...';

// ** POSITION
$absolute-left: calc((-100vw + 100%) / 2);
