.panel-pane {
  float: left;
  width: 100%;
  margin-bottom: 20px;
}

.panel-2col-stacked {
  .panel-panel {
    width: 100%;
    float: left;
    .inside {
      margin-left: 0;
      margin-right: 0;
    }
  }
  .panel-col-first {
    @include break2 {
      @include span-columns(8);
    }
  }
  .panel-col-last {
    @include break2 {
      @include span-columns(4);
      @include omega;
    }
  }
}

// what page??
.panels-flexible-region {
  float: left;
  width: 100%;
  &.two-third {
    @include break2 {
      @include span-columns(8);
    }
  }
  &.one-third {
    @include break2 {
      @include span-columns(4);
    }
  }
  &.last {
    @inlude break2 {
      @include omega;
    }
  }
}
