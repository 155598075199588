// fields
.field-name-field-vac-dienstverband,
.field-name-field-vac-publish-date,
.field-name-agro-glue-node-city {
  float: left;
}

.field-name-field-vac-dienstverband {
  clear: left;
  position: relative;
  text-transform: uppercase;
  padding-right: 20px;
  margin-bottom: 4px;
}

.field-name-field-vac-publish-date {
  color: rgba($coil, .75);

  &:before {
    content: '-';
    display: inline;
  }
}

.field-name-agro-glue-node-city {
  line-height: 15px;
  @include font-awesome($marker, $coil, 15px);

  &:before {
    padding-right: 7px;
    display: block;
    float: left;
    line-height: 1;
  }
}

.field-name-vacancy-feature-jobapply {
  margin-top: 50px;
  a {
    @include button;
    margin-bottom: 20px;

    @include break1 {
      @include span-columns(6 of 12);
      @include shift(3 of 12);
    }
    @include break2 {
      @include span-columns(4 of 8);
      @include shift(2 of 8);
    }
  }
}

.node-vacancy {
  position: relative;
  float: left;
  width: 100%;

  > h2 {
    @extend %h2-big-lower;
  }

  > h3 {
    @extend %h3-title-lower;
  }

  &.view-mode-small_teaser,
  .group-summary,
  .group-descriptions {
    position: relative;

    background: $white;
    border-bottom: 1px solid $border-color;
  }

  &.node-teaser,
  &.view-mode-small_teaser,
  .group-summary {
    // yellow line
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 10px;
      background: $yellow;
      @include transition(all .1s $timing);
    }
  }

  &.node-teaser,
  &.view-mode-small_teaser {
    h2,
    h3 {
      position: relative;
      margin: 0;
      float: left;
      clear: both;
      //text-transform: inherit;
      margin-bottom: 4px;
      &:before {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 1px;
        height: 1px;
        background: transparent;
        @include transition(all $duration $timing);
      }
    }

    // place read more link over all content
    .field-name-node-link {
      text-align: inherit;
      a {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        text-indent: -99999px;

        &:before {
          content: none;
        }
      }
    }

    &:hover {
      &:before {
        width: 13px;
      }
      > h2,
      > h3 {
        &:before {
          background: $border-color;
          width: 100%;
        }
      }
    }
  }

  &.node-teaser {
    margin-bottom: 20px;
    padding: 15px 40px;

    .field-name-agro-glue-node-city {
      clear: both;
    }
  }

  &.view-mode-small_teaser {
    margin-bottom: 17px;
    padding: 7px 26px;
    &:before {
      width: 6px;
      @include transition(all .07s $timing);
    }

    &:hover {
      &:before {
        width: 9px;
      }
    }
    .field-name-field-vac-dienstverband {
      line-height: 15px;
      margin-bottom: 0;
      min-width: 50%;
    }
  }

  // detail page
  // for H1 styling, see general
  &.view-mode-info_block,
  .group-summary,
  .group-descriptions {
    float: left;
    width: 100%;
    label {
      font-weight: bold;
      margin-left: 0;
      padding-right: 32px;
    }
    > div {
      display: table-row;
    }
  }

  &.view-mode-info_block,
  .group-summary {
    label {
      display: table-cell;
    }
    .field-item--wrapper {
      display: table-cell;
      padding-left: 0;
      p {
        padding-left: 0;
      }
    }
  }
  .group-summary {
    padding: 0 0 17px 0;
    margin-bottom: 20px;
    div.group-intro {
      float: left;
      width: 100%;
      display: block;
    }
    label {
      padding-left: 50px;
    }
    .field-item--wrapper {
      padding-right: 50px;
    }

    .field-name-field-vac-dienstverband,
    .field-name-field-vac-publish-date {
      display: block;
      float: left;
      width: auto;
      padding-right: 20px;
    }
    .field-name-field-vac-dienstverband {
      padding-left: 50px;
    }
    .field-name-vacancy-target-audience {
      margin-top: 15px;
      padding-left: 50px;
      display: block;
      float: left;
    }
    .field-name-field-vac-publish-date {
      padding-left: 0;
    }
    .field-name-agro-glue-node-city {
      display: block;
      float: left;
      width: 100%;
      padding-left: 50px;
      margin-bottom: 25px;
    }
  }
  .group-descriptions {
    padding: 70px 30px 0;
    @include break1 {
      padding: 70px 70px 0;
    }
    label {
      margin-bottom: 17px;
    }
    > div {
      padding: 0;
      float: left;
      width: 100%;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .field-item--wrapper {
      float: left;
      width: 100%;
      padding: 0 20px;
    }
  }
}
