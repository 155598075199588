.view-id-testimonials {
  float: left;
  width: 100%;
  &.view-display-id-panel_pane_1 {
    .owl-carousel {
      @include break2 {
        width: calc(100% + #{$gutter});
        margin-left: -#{$gutter / 2};
        margin-right: -#{$gutter / 2};
      }
    }
    .owl-item {
      padding: 0 #{$gutter / 2};
    }
  }
  &.view-display-id-panel_pane_2 {
    margin-bottom: 20px;
  }
}

.testimonials-panel_pane_1 {
  float: left;
  width: 100%;
}
