.faq_feature-faq_feature_random_question {
  @extend .cta-block-dark;
  h2 {
    color: $white;
  }

  a {
    color: $white;
  }
}
