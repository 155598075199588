.views--exp-custom_faq-faq_search,
.views--exp-faq-panel_pane_1,,
.view-custom-faq {
  background-color: $white;
  border-bottom: 1px solid $border-color;
  padding: 0 20px 20px;

  .pane-title {
    text-align: center;
  }
  .views-exposed-widgets {
    position: relative;
  }

  .views-exposed-widget {
    width: 100%;
  }
  .views-submit-button {
    @include font-awesome($search, $coil, 10px);
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
    &:before {
      position: absolute;

      @include square(28px);
      text-align: center;
      line-height: 28px;
      pointer-events: none;
    }
    input {
      @include square(28px);
      overflow: hidden;
      text-indent: -9999px;
      padding: 0;
      display: block;
      background: transparent;
      &:hover {
        background-color: $yellow;
      }
    }
  }
  .view-empty {
    padding: 20px 40px 0;
  }
}
