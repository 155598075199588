.agro_glue-homepage_video {
  @include full-bg($coil);
  padding: 30px 0;

  &:before {
    @include break2 {
      margin-left: -1 * flex-gutter();
    }
  }
  //ratio
  .embed-container {
    @include ratio(iframe, 5, 3);
  }
}
