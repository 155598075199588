// -- FORMS -- //

// ** INPUTS
input {
  @include placeholder {
    color: $placeholder;
    opacity: .75;
    @include font-size(11, 28);
  }
}
// Types email, number, password, search, tel, text, url, color, date, datetime,
// datatime-local, month, time, week
#{$all-text-inputs},
input[type="file"],
textarea {
  border-radius: 0px;
  height: 28px;
  padding: 0 20px;
  margin-bottom: 6px;
  width: 100%;
  outline: none;
  border: 1px solid $light-grey;
  -webkit-appearance: none;
  -moz-appearance: none;
  @include font-size(11, 28);

  &:focus,
  &:focus:hover {
    border-color: $yellow;
    outline: 3px;
  }
  &:disabled {
    background: lighten($border-color, 10%);
  }
}
input[type="file"] {
  width: auto;
  background: $white;
  padding: 0;
  @include break1max {
    width: 100%;
  }
}


form[id^=webform] {
  background: $white;

}

.webform-component {
  margin-bottom: 7px;
}

  // hairline inside input fields
.form-type-textfield,
.form-type-select,
.webform-component {
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: calc(100% - 2px);
    height: 1px;
    background: rgba($coil, .2);
    bottom: 7px;
    left: 1px;
    right: 1px;
  }
}
.webform-component-textarea {
  &:after {
    bottom: 1px;
  }
}
.webform-component-checkboxes {
  &:after {
    background: none;
  }
}

input.admin-menu-search {
  height: auto;
}

textarea {
  padding: 10px;
  height: 80px;
  &:focus,
  &:focus:hover {
    border-color: $yellow;
    outline: 3px;
  }
  .form-textarea-wrapper & {
    margin-bottom: 6px;
  }
}
input[type='range'] {
  -webkit-appearance: none;
  width: 100%;
  border-radius: 5px;
  height: 5px;
  border: 1px solid $border-color;
  background-color: $light-grey;
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    background-color: $yellow;
    border: 1px solid $light-grey;
    @include square(15px);
    border-radius: 50%;
    cursor: pointer;
  }

  &:focus,
  &:focus:hover {
    outline: 0;
  }
}
input[type='radio'],
input[type='checkbox'] {
  -webkit-appearance:none;
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 10px 0 0;
  padding: 0;
  background: $light-grey;
  border: 1px solid $light-grey;
  border-radius: 0;
  vertical-align: top;
  @include square(15px);
  outline: none;
  @include transition(border-color $duration $timing, background-color $duration $timing);
  &:hover,
  &:focus {
    border-color: darken($border-color, 20%);
    outline: 0;
    cursor: pointer;
  }
  &:disabled {
    opacity: .5;
    background: #E2E2E2;
    &:hover {
      border-color: $border-color;
      cursor: default;
    }
  }
  & + label {
    display: inline-block;
    margin-left: 0;
    margin-bottom: 0;
    a {
      font-weight: bold;
      font-style: italic;
      text-decoration: underline;
    }
  }
}

input[type='checkbox'] {
  background-position: 50% 50%;
  @include transition(background-color $timing .1s);
  &:checked,
  &:disabled:checked {
    background-image: url('../images/check.svg');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-color: $yellow;
    border: 0;
  }
  & + label {
    display: initial;
  }
}
input[type='radio'] {
  border-radius: 50%;
  box-shadow: inset 0px 0px 0px 0px $yellow;
  @include transition(box-shadow .1s cubic-bezier(0.28,0.63,0.77,0.17));
  &:checked,
  &:disabled:checked {
    background: $white;
    box-shadow: inset 0px 0px 0px 5px $yellow;
  }
}
// Types button, reset, submit
#{$all-button-inputs} {
  @include button;
  outline: 0;
  &:focus,
  &:focus:hover {
    outline: 0;
  }
}
input.error {
  color: red;
}

// ** LABELS
label {
  margin: 0 0 5px 20px;
  //margin-bottom: 5px;
  display: inline-block;
  @include font-size(12);
  text-transform: uppercase;
  letter-spacing: $letter-spacing-small;
  &.required {
    &:after {
      content:'*';
      color: red;
      margin-left: 5px;
    }
  }
}

// ** SELECTS
select:not(.ui-datepicker-month,.ui-datepicker-year) {
  height: 28px;
  width: 100%;
  margin-bottom: 6px;
  background-color: $white;
  border: 1px solid $light-grey;
  border-radius: 0 !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image:
    url('../images/dropdown.svg'),
    linear-gradient(45deg, transparent 50%, $light-grey 50%),
    linear-gradient(45deg, transparent 50%, $border-color 50%),
    linear-gradient(135deg, $light-grey 50%, transparent 50%),
    linear-gradient(135deg, $border-color 50%, transparent 50%),
    none,
    linear-gradient(135deg,  $light-grey,  $light-grey);
  background-position:
    calc(100%) 0,
    calc(100% - 13px) 10px,
    calc(100% - 13px) 12px,
    calc(100% - 7px) 10px,
    calc(100% - 7px) 12px,
    calc(100% - 2.5em) 0px,
    calc(100%) 0;
  background-size:
    27px 27px,
    6px 7px,
    6px 7px,
    6px 7px,
    6px 7px,
    1px 1.5em,
    27px 25px;
  background-repeat: no-repeat;
  padding: 0 40px 0 20px;
  @include font-size(11);
  color: rgba($placeholder, .75);

  &.error {
    color: $error;
  }

  &:focus,
  &:focus:hover {
    border-color: $yellow;
    outline: 3px;
  }
  &:disabled {
    background: lighten($border-color, 10%);
  }
  // Remove fugly arrow in IE
  &::-ms-expand {
    display: none;
  }

  &:after {
    content: '';
    display: block;
    background: red;
    width: 20px;
    height: 20px;
  }
}

// ** HINTS
small,
.password-suggestions {
  display: block;
  color: lighten(grey, 20%);
  margin-top: 10px;
  @include font-size(10);
}

// ** FIELDSETS
fieldset {
  border: none;
  background: $white;
  legend {
    color: $coil;
    font-weight: bold;
    padding: 0 10px;
    float: left;
    width: 100%;
    font-weight:bold;
    text-transform: uppercase;
    @extend %h2-big;
    margin-top: 25px;
    margin-bottom: 25px;
  }
}

