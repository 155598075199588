// -- FOOTER -- //
footer {
  margin-top: 10px;
  padding-top: 20px !important;
  @include outer-container;
  @include full-bg($coil);
  color: $white;

  .footer-region {
    clear: both;
  }
  .footer-social-links {
    text-align: right;
  }
}

.footer-region-menu {
  @include break2 {
    @include span-columns(9);
    @include omega();
    margin-right: 0;
  }
}

.footer-social-links,
.footer-region-contact {
  width: 100%;
  float: left;
  @include break1 {
    width: flex-grid(6) + flex-gutter();
  }
  @include break2 {
    width: flex-grid(3) + flex-gutter();
  }
}

.footer-region-contact {
  margin-bottom: 20px;
}

.footer-social-links {
  text-align: center;
  float: right;
  @include omega;
  @include break2 {
    text-align: right;
  }
  .block-agro-glue-footer-social-media {
    display: inline-block;
    float: right;
    a {
      overflow: hidden;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.footer-copyright {
  @include full-bg($black);
  .footer-copyright-region {
    color: $white;
    @include break2max {
      text-align: center;
    }
  }
}

.block-agro-glue-footer-copyright-block {
  line-height: 1;
  padding: 19px 0;

  @include break2 {
    float: right;
  }
  > ul {
    float: left;
    width: 100%;
    @include break2 {
      width: auto;
    }
  }
  > span,
  > ul {
    @include break2 {
      float: left;
      vertical-align: top;
    }
  }
  > span {
    display: block;
    padding-bottom: 10px;
    @include break2 {
      border-right: 1px solid $white;
      margin-right: 7px;
      padding-right: 7px;
      padding-bottom: 0;
    }
  }
}

.block-agro-glue-footer-number-block {
  padding: 5px 0;
  @include break2 {
    float: left;
  }
  .footer-number-lines {
    @include break2 {
      float: left;
      margin-right: 20px;
    }
  }
  .federgon-logo {
    margin-top: 6px;
    img {
      margin: 0 auto;
    }
    @include break2 {
      float: left;
    }
    //height: 40px;
  }
}
