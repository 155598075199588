table.responsive {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
  @extend %m-p-reset;
  border: 1px solid $border-color;
  thead {
    display: none;
    background: $coil;
    color: $white;
    border: 1px solid $coil;
    text-align: left;
    th {
      padding: 10px;
      border-right: 1px solid $white;
      &:last-child {
        border-right-color: $coil;
      }
    }
  }
  tr {
    border: 1px solid $border-color;
    display: block;
    td {
      display: block;
      text-align: right;
      padding: 10px;
      &:before {
        content: attr(data-title)': ';
        font-weight: bold;
        float: left;
      }
    }
    &.odd {
      background: $light-grey;
    }
  }
  @include break1 {
    thead {
      display: table-row-group;
    }
    tr {
      border: 0;
      display: table-row;
      td {
        display: table-cell;
        border: 1px solid $border-color;
        text-align: left;
        &:before {
          content: '';
        }
      }
    }
  }
}
