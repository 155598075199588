// -- USER -- //

#user-login,
#user-pass {
  float: none;
  background-color: #F4F4F4;
  width: 300px;
  padding: 20px;
  margin: 0px auto;
  text-align: center;
  label {
    display: none;
  }
  * {
    width: 100%;
  }
  > div {
    margin: 10px 0px;
  }
  input[type=submit] {
    margin-left: 0;
    border: 0;
    color: $white;
  }
  small {
    @include font-size(10);
  }
}
#user-pass {
  input[type=text] {
    margin-top: 20px;
  }
}
.page-user-password {
  .sub-header {
    display: none;
  }
}
#user-login {
  small {
    display: none;
  }
}
.confirm-parent {
  .password-confirm {
    .ok {
      color: $status;
    }
    .error {
      color: $error;
    }
  }
}
