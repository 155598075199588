.node-izw-aanbod {
  float: left;
  width: 100%;

  // fields
  .field-name-izw-feature-detail-links {
    padding: 0 30px;
    @include break1 {
      padding: 0 70px;
    }
    @include font-size($h2-size);
    label {
      margin-left: 35px;
      font-weight: bold;
      text-transform: uppercase;
    }
    ul {
      list-style: none;
      padding-left: 0;
      li {
        margin-bottom: 15px;
        @include break1 {
          float: left;
          width: 1/3 * 100%;
          padding-right: 20px;
          &:nth-child(3n + 1) {
            clear: left;
          }
        }
      }
    }
    a {
      text-decoration: underline;
      &:hover {
        opacity: .7;
      }
    }
  }
  &.view-mode-full {
    .field-name-body {
      margin-bottom: 20px;
    }
  }

  &.view-mode-teaser {
    background: transparent;
    border-bottom: 0;
    .field-name-body {
      padding: 20px 30px;
      @include break1 {
        padding: 20px 70px;
      }
      p {
        padding-left: 0;
      }
    }
    .group-left,
    .group-right {
      margin-bottom: 40px;
    }
    .group-left {
      @extend .node-teaser;
      float: left;
      width: 100%;

      @include break2 {
        @include span-columns(8 of 12);
      }
      .field-name-node-link {
        margin: 0 20px;
        padding-bottom: 20px;
        @include break1 {
          @include span-columns(6 of 12);
          @include shift(3 of 12);
        }
        @include break2 {
          @include span-columns(4 of 8);
          @include shift(2 of 8);
        }
      }
    }
    .group-right {
      @include break2 {
        @include span-columns(4 of 12);
        @include omega;
      }
    }
    h2 {
      @extend %h2-black;
    }
  }
}

body.node-type-izw-aanbod {
  .aanbod_detail-panel_pane_1 {
    @include full-bg($white);
    float: left;
    width: 100%;
    > .pane-title {
      float: left;
      width: 100%;
      text-align: center;
      margin: 30px 0;
    }
  }
  .view-id-cases.view-display-id-panel_pane_4 {
    margin-bottom: 0;
  }
}
