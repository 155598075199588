.view-id-offices {
  @include full-bg($coil);
  padding: 65px 0 24px;
  .view-content {
    // flexbox baby!
    @include display-flex();
    @include flex-wrap(wrap);
    @include flex-justify-content(space-around);
  }
  .node {
    margin-bottom: 30px;
    @include break1 {
      @include span-columns(6);
      @include omega(2n);
    }
    @include break2 {

      &:nth-child(2n + 1) {
        clear: none;
      }
      &:nth-child(2n + 2) {
        margin-right: flex-gutter();
      }
      @include span-columns(4);
      @include omega(3n);
    }
  }
}
