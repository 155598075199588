// -- SET BREAKPOINTS -- //
// -- Responsive indicattors for JS (remove color & position when done)

body:after {
  content:'breakpoint0';
  background: red;
  position: fixed;
  bottom:0px;
  right:0px;
  display: block;
  visibility: hidden;
  z-index: 444;
}
body.theme-debug-mode:after {
  visibility: visible;
}

@if variable-exists(breakpoint0a) {
  @include media($breakpoint0a) {
    body.after {
      content:'breakpoint0a';
      background: #FF5700;
      position: fixed;
      bottom: 0px;
      right: 0px;
    }
  }
}

@if variable-exists(breakpoint1) {
  @include media($breakpoint1) {
    body:after {
      content:'breakpoint1';
      background: orange;
      position: fixed;
      bottom: 0px;
      right: 0px;
    }
  }
}

@if variable-exists(breakpoint1a) {
  @include media($breakpoint1a) {
    body:after {
      content: 'breakpoint1a';
      background: #FFCE00;
      position: fixed;
      bottom: 0px;
      right: 0px;
    }
  }
}

@if variable-exists(breakpoint2) {
  @include media($breakpoint2) {
    body:after {
      content: 'breakpoint2';
      background: yellow;
      position: fixed;
      bottom: 0px;
      right: 0px;
    }
  }
}

@if variable-exists(breakpoint3) {
  @include media($breakpoint3) {
    body:after {
      content: 'breakpoint3';
      background: green;
      position: fixed;
      bottom: 0px;
      right: 0px;
    }
  }
}

@if variable-exists(breakpoint4) {
  @include media($breakpoint4) {
    body:after {
      content: 'breakpoint4';
      background: blue;
      position: fixed;
      bottom: 0px;
      right: 0px;
    }
  }
}
