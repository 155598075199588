.node-case {
  margin-bottom: 15px;
  overflow: hidden;

  // fields
  .field-name-field-image {
    img {
      margin: 0;
      display: block;
      width: 100%;
      height: auto;
    }
  }

  // view modes
  &.view-mode-teaser {
    &:after {
      content: none;
    }
    @include break1 {
      @include display-flex;
    }
    .field-name-body {
      @include break2 {
        padding-left: 40px !important;
      }
    }
    @include break2 {
      @include span-columns(10);
      @include shift(1);
    }
  }
  .group-left,
  .group-right {
    float: left;
    width: 100%;
  }
  .group-left {
    padding: 5px 30px;
    h3 {
      @extend %h2-accent-wide;
    }
    @include break1 {
      width: flex-grid(6, 12) + flex-gutter();
    }
    @include break2 {
      width: flex-grid(6, 10) + flex-gutter(10);
    }
  }

  .group-right {
    position: relative;
    @include break1 {
      @include span-columns(6 of 12);
      @include omega;
      float: right;
    }
    @include break2 {
      @include span-columns(4 of 10);
      @include omega;
      float: right;
    }
  }
  &.view-mode-full {
    border-bottom: 1px solid $border-color;
  }
}
// page detail / full node
.node-type-case {
  h1 {
    padding-bottom: 43px;

    &:after {
      bottom: 28px;
    }
  }
  .view-mode-full {
    .field-name-body {
      padding-top: 10px;
    }
    .slick,
    .slick-wrapper {
      ~ .field-name-body {
        padding-top: 65px;

      }
    }
  }

  // slick carousel
  .slick--carousel {
    background: $coil;
  }
}
