.field-name-node-link {
  text-align: right;
  a {
    position: relative;
    padding: 0 5px 3px;
    margin-right: -3px;

    &:before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      height: 1px;
      width: 1px;
      background: transparent;
      @include transition(all $duration $timing);
    }
    @include font-awesome($angle-right, $coil, 16px, after);
    &:after {
      line-height: 1;
      margin-left: 10px;
      vertical-align: top;
      padding-top: 2px;
    }
    &:hover {
      &:before {
        width: 100%;
        background-color: $coil;
      }
    }
  }
  // CT specific buttons
  .node-izw-aanbod .group-left & {
    text-align: center;
    a {
      margin-right: initial;
      @include button();
      width: 100%;
      &:before,
      &:after {
        content: none;
      }
    }
  }
}
