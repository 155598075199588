%h1-title {
  @include font-size($h1-size, 24);
  letter-spacing: $letter-spacing-small;
  color: $h1-colour;
  word-wrap: break-word;

  &:after {
    content: none;
  }
}

%h1-title-underline {
  @extend %h1-title;
  position: relative;
  padding-bottom: 15px;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    display: block;
    height: 3px;
    width: 44px;
    background: $coil;
  }
}

%h1-alt {
  @include font-size($h1-size-alt, $h1-size-alt);
  color: $h1-color-alt;
  margin-bottom: 40px;
  padding-bottom: 23px;
  position: relative;
  text-transform: uppercase;
  font-weight: bold;

  @include break1 {
    margin-left: 30px;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    display: block;
    height: 8px;
    width: 60px;
    background: $coil;
  }
}

// no letterspacing
%h2-title {
  @include font-size($h2-size);
  color: $h2-colour;
  letter-spacing: $letter-spacing-small;
}

// letter spacing stuff.
%h2-title-wide {
  @extend %h2-title;
  letter-spacing: $letter-spacing-big;
}

%h2-big {
  @include font-size(15);
}

// used at vacancy overview
%h2-big-lower {
  @include font-size(15);
  text-transform: initial;
  letter-spacing: initial;
}

%h2-bigger {
  @include font-size($h1-size, 18);
  text-transform: uppercase;
}

// used at news teaser
%h2-bigger-underline {
  @extend %h2-bigger;
  position: relative;
  padding-bottom: 15px;
  min-height: 55px;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    display: block;
    height: 3px;
    width: 44px;
    background: $coil;
  }
}

%h2-body {
  @include font-size(13);
  letter-spacing: $letter-spacing-small;
}

// no letterspacing
%h2-accent {
  @include font-size(15);
}

%h2-accent-slim {
  @extend %h2-accent;
  letter-spacing: $letter-spacing-small;
}

%h2-accent-wide {
  @extend %h2-accent;
  letter-spacing: $letter-spacing-small;
}

%h2-black {
  @include font-size(20, 50);
  letter-spacing: $letter-spacing-big;
  text-align: center;
  background: $coil;
  color: $white;
  margin-top: 0;
}

%h3-title {
  @include font-size($h3-size);
  color: $h3-colour;
  //text-transform: uppercase;
}

%h3-title-lower {
  text-transform: initial;
}

%h3-body {
  @include font-size($h4-size);
  color: $h4-colour;
}

%h4-title {
  @include font-size($h4-size);
  color: $h4-colour;
  text-transform: uppercase;
  font-weight: bold;
}

%h4-body {
  @include font-size($h5-size);
  color: $h4-colour;
}
