// -- MIXINS -- //

// -- For more Bourbon mixins see: http://bourbon.io/docs/

// INCLUDED MIXINS
// * font-size
// * font-awesome
// * li-width
// * button
// * square
// * columns
// * full-bg

// ** MAIN MIXINS
// -- Mixins included in every project

// *
// * Font-size mixin: easily set font-size and line-height of an element
// * NOTE: default font-size is 14, default line-height is (14 * 1.5)
// * USAGE: @include font-size(15) (don't use px!) -> sets font-size to 15 and line-height to (15 * 1.5)
// *
@mixin font-size($size:11, $line: $size * 1.5) {
  font-size: rem($size);
  line-height: rem($line);
}

// *
// * Font-awesome mixin: add a font-awesome icon before or after an element
// * NOTE: before is default, margin is default 0.
// * USAGE: @include font-awesome($facebook, $red, 14px, before, middle, 0 5px 0 0);
// *
@mixin font-awesome($content, $color, $size, $position:before, $align: middle, $margin...) {
  &:#{$position} {
    content: $content;
    font-family: 'FontAwesome';
    font-size: $size;
    display: inline-block;
    color: $color;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    $margin: if(length($margin) == 0, 0px, $margin);
    margin: $margin;
    vertical-align: $align;
  }
}

// *
// * Li-width mixin
// *
@mixin li-width($max) {
  @for $i from 1 through $max {
    li:first-child:nth-last-child(#{$i}),
    li:first-child:nth-last-child(#{$i}) ~ li {
      float: left;
      width: 100%/$i;
    }
  }
}

// *
// * Button mixin: use this for buttons used on the site
// * NOTE: default is the default type, global var button-color is default background-colour and white is default colour
// * NOTE: remove what doesn't apply to the buttons used on the site or edit to your needs
// *
@mixin button($type:'default', $background:$button-colour, $color:$white) {
  border-radius: 0px;
  border:none;
  text-transform: uppercase;
  display: inline-block;
  color: $color;
  background: $background;
  font-weight: 700;
  text-decoration: none;
  text-align: center;
  letter-spacing: $letter-spacing-small;
  @include transition(background $duration $timing);
  @include break1max {
    width: 100%;
  }
  &:hover {
    //background: lighten($background, 10%);
    background: $yellow-light;
    color: $color;
  }
  &:disabled {
    background: lighten($background, 20%);
  }
  @if ($type == 'default') {
    @include font-size(12);
    line-height: 14px;
    padding: 8px 20px;
  }
  @if ($type == 'small') {
    @include font-size(12);
    text-transform: lowercase;
    padding: 5px 10px;
    background: darken($background, 10%);
  }
}

// *
// * Square mixin
// *
@mixin square($dimensions) {
  width: $dimensions;
  height: $dimensions;
}

// *
// * Columns mixin: use this to create columns
// * NOTE: $number is the number of columns you want
// *
@mixin columns($number, $flex:null) {
  @if($number > 1) {
    @include span-columns($grid-columns/$number);
    @include omega(#{$number}n);
    @if($flex != null) {
      @include display-flex;
    }
    &:nth-child(#{$number}n+1) {
      clear: both;
    }
  }
  @else {
    @include fill-parent();
  }
}

// *
// * Omega-reset mixin: use this to change the number of set columns at any breakpoint
// * NOTE: $nth is the previously set columns
// *
@mixin omega-reset($nth) {
  &:nth-child(#{$nth}) {
    margin-right: flex-gutter();
  }
  &:nth-child(#{$nth}+1) {
    clear: none;
  }
}

// *
// * Adds fullwidth background using given colour.
// *
@mixin full-bg ($color, $position: before) {
  position: relative;
  z-index: 5;
  &:#{$position} {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    margin-left: calc((-100vw + 100%) / 2);
    height: 100%;
    width: 100vw;
    background-color: $color;
    z-index: -1;
  }
}

// *
// * Adds fullwidth background using given colour to the right.
// *
@mixin full-bg-right ($color, $position: after) {
  position: relative;
  z-index: 5;
  &:#{$position} {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    //margin-left: calc((-50vw + 100%) / 2);
    //margin-left: 50vw;
    height: 100%;
    width: 100vw;
    background-color: $color;
    z-index: -1;
  }
}

// *
// * Max-width: provides a max-width to an element and centers the element it's used on.
// * USAGE: @include max-width;           This will take a max-width defined by the mixin below.
// * USAGE: @include max-width(123px);    This will use the value included.
// *
@mixin max-width($local-maximum-width: null) {
  @include clearfix;
  @if ($local-maximum-width == null) {
    margin: 0 auto;
    max-width: 580px;
    @include break1 {
      max-width: 960px;
    }
    @include break2 {
      max-width: 990px;
    }
  }
  @else {
    max-width: $local-maximum-width;
    margin: 0 auto;
  }
}

// *
// * Links mixin: use this to style the links
// *
@mixin links ($link, $hover, $visited: $hover, $active: $hover) {
  color: $link;
  &:visited {
    color: $visited;
  }
  &:hover {
    color: $hover;
  }
  &:active,
  &:focus {
    color: $active;
  }
}

// ** CUSTOM MIXINS
// -- Add your own custom mixins
// (If you think you have a mixin that we would all benefit from, send it to Niels! We'll include it in the base-theme.)

@mixin ratio($element, $ratio-x: 4, $ratio-y: 3, $pos: before) {
  position: relative;
  &:#{$pos} {
    display: block;
    content: "";
    width: 100%;
    padding-top: (100% / $ratio-x) * $ratio-y;
  }
  #{$element} {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: (100% / $ratio-x) * $ratio-y;
    min-height: 100%;
  }
}
