$icon-size: 18px;
.facebook {
  @include font-awesome($facebook, $white, $icon-size);
}
.twitter {
  @include font-awesome($twitter, $white, $icon-size);
}
.linkedin {
  @include font-awesome($linkedin, $white, $icon-size);
}

.facebook,
.twitter,
.linkedin {
  display: block;
  float: left;
  text-indent: -99999px;
  margin-right: 16px;
  margin-bottom: 20px;
  max-height: 20px;

  &:before {
    text-indent: 0;
    display: block;
  }
  &:hover {
    &:before {
      color: $yellow;
    }
  }
}
