.page-ik-zoek-werkers {
  .panels-flexible-region-inside {
    > div {
      width: 100%;
      float: left;
    }
    .izw_feature-izw_feature_aanbod_items {
      @include break2 {
        @include span-columns(8 of 12);
      }
    }
    .bean-contact-default {
      @include break2 {
        @include span-columns(4 of 12);
        @include omega;
      }
    }
  }
}
