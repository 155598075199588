// *
// * Display-flex mixin: causes an element to generate a block-level flex container box
// * NOTE: if inline is given as parameter, an element will generate a inline-level flex container box
// *
@mixin display-flex($inline: null) {
  @if ($inline == null) {
    display: -webkit-box; // Old iOS & BB
    display: -ms-flexbox; // IE 10
    display: -webkit-flex; // Newer iOS & BB
    display: flex; // All the cool kids
  }
  @else if ($inline == inline) {
    display: -webkit-inline-box; // Old iOS & BB
    display: -ms-inline-flexbox; // IE 10
    display: -webkit-inline-flex; // Newer iOS & BB
    display: inline-flex; // All the cool kids
  }
}

// *
// * Flex mixin: shorthand for setting the flex-grow, flex-shrink and flex-basis
// * NOTE: accepted values: <flex-grow> | <flex-shrink> | <flex-basis>
// * NOTE: default: 1 null null
// *
@mixin flex($fg: 1, $fs: null, $fb: null) {
  $fg-boxflex: $fg; // Set a variable to be used by box-flex properties
  @if (type-of($fg) == 'list') { // Box-Flex only supports a flex-grow value so let's grab the first item in the list and just return that.
    $fg-boxflex: nth($fg, 1);
  }
  -webkit-box-flex: $fg-boxflex; // Old iOS & BB
  -ms-flex: $fg $fs $fb; // IE 10;
  -webkit-flex: $fg $fs $fb; // Newer iOS & BB
  flex: $fg $fs $fb; // All the cool kids
}

// *
// * Flex-direction mixin: flex-direction property that defines how flex items are placed in the flex container
// * NOTE: accepted values: row (default) | row-reverse | column | column-reverse
// *
@mixin flex-direction($direction: row) {
  @if ($direction == row-reverse) { // Old iOS & BB
    -webkit-box-direction: reverse;
    -webkit-box-orient: horizontal;
  }
  @else if ($direction == column) {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
  }
  @else if ($direction == column-reverse) {
    -webkit-box-direction: reverse;
    -webkit-box-orient: vertical;
  }
  @else {
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
  }
  -ms-flex-direction: $direction; // IE 10;
  -webkit-flex-direction: $direction; // Newer iOS & BB
  flex-direction: $direction; // All the cool kids
}

// *
// * Flex-wrap mixin: flex-wrap property controls whether the flex container is single-line or multi-line
// * NOTE: accepted values: nowrap (default) | wrap | wrap-reverse
// *
@mixin flex-wrap($wrap: nowrap) {
  // No Webkit Box fallback
  @if ($wrap == nowrap) { // IE 10
    -ms-flex-wrap: none;
  }
  @else {
    -ms-flex-wrap: $wrap;
  }
  -webkit-flex-wrap: $wrap; // Newer iOS & BB
  flex-wrap: $wrap;  // All the cool kids
}

// *
// * Flex-flow mixin: shorthand for setting the flex-direction and flex-wrap
// * NOTE: accepted values: <flex-direction> | <flex-wrap>
// * NOTE: default: row nowrap
// *
@mixin flex-flow($flow: (row nowrap)) {
  // No Webkit Box fallback
  -ms-flex-flow: $flow; // IE 10
  -webkit-flex-flow: $flow; // Newer iOS & BB
  flex-flow: $flow; // All the cool kids
}

// *
// * Flex-order mixin: order property controls the order in which flex items appear within their flex container
// * NOTE: accepted values: <negative integer> | 0 (default) | <positive integer>
// *
@mixin flex-order($int: 0) {
  -webkit-box-ordinal-group: $int + 1; // Old iOS & BB
  -ms-flex-order: $int; // IE 10
  -webkit-order: $int; // Newer iOS & BB
  order: $int; // All the cool kids
}

// *
// * Flex-grow mixin: flew-grow property sets the grow-factor
// * NOTE: accepted values: 0 (default) | <positive integer>
// *
@mixin flex-grow($int: 0) {
  -webkit-box-flex: $int; // Old iOS & BB
  -ms-flex-positive: $int; // IE 10
  -webkit-flex-grow: $int; // Newer iOS & BB
  flex-grow: $int; // All the cool kids
}

// *
// * Flex-shrink mixin: flew-grow property sets the shrink-factor
// * NOTE: accepted values: 0 (default) | <positive integer>
// *
@mixin flex-shrink($int: 0) {
  -webkit-box-flex: $int; // Old iOS & BB
  -ms-flex-negative: $int; // IE 10
  -webkit-flex-shrink: $int; // Newer iOS & BB
  flex-shrink: $int; // All the cool kids
}

// *
// * Flex-basis mixin: flew-grow property sets the flex basis
// * NOTE: accepted values: auto (default) | <positive integer>
// *
@mixin flex-basis($basis: auto) {
  -ms-flex-preferred-size: $basis; // IE 10
  -webkit-flex-basis: $basis; // Newer iOS & BB
  flex-basis: $basis; // All the cool kids
}

// *
// * Flex-justify-content mixin: justify-content property aligns items horizontally
// * NOTE: accepted values: flex-start (default) | flex-end | center | space-between | space-around
// *
@mixin flex-justify-content($justify: flex-start) {
  @if ($justify == flex-start) {
    -webkit-box-pack: start; // Old iOS & BB
    -ms-flex-pack: start; // IE 10
  }
  @else if ($justify == flex-end) {
    -webkit-box-pack: end; // Old iOS & BB
    -ms-flex-pack: end; // IE 10
  }
  @else if ($justify == space-between) {
    -webkit-box-pack: justify; // Old iOS & BB
    -ms-flex-pack: justify; // IE 10
  }
  @else if ($justify == space-around) {
    -ms-flex-pack: distribute; // IE 10
  }
  @else {
    -webkit-box-pack: $justify; // Old iOS & BB
    -ms-flex-pack: $justify; // IE 10
  }
  -webkit-justify-content: $justify; // Newer iOS & BB
  justify-content: $justify; // All the cool kids
}

// *
// * Flex-align-items mixin: align-items property aligns items vertically
// * NOTE: accepted values: flex-start | flex-end | center | baseline | stretch (default)
// *
@mixin flex-align-items($align: stretch) {
  @if ($align == flex-start) {
    -webkit-box-align: start; // Old iOS & BB
    -ms-flex-align: start; // IE 10
  }
  @else if ($align == flex-end) {
    -webkit-box-align: end; // Old iOS & BB
    -ms-flex-align: end; // IE 10
  }
  @else {
    -webkit-box-align: $align; // Old iOS & BB
    -ms-flex-align: $align; // IE 10
  }
  -webkit-align-items: $align; // Newer iOS & BB
  align-items: $align; // All the cool kids
}

// *
// * Flex-align-self mixin: align-self property is applied to individual items
// * NOTE: accepted values: flex-start | flex-end | center | baseline | stretch (default)
// *
@mixin flex-align-self($align-self: auto) {
  // No Webkit Box Fallback
  @if ($align-self == flex-start) { // IE 10
    -ms-flex-item-align: start;
  }
  @else if ($align-self == flex-end) {
    -ms-flex-item-align: end;
  }
  @else {
    -ms-flex-item-align: $align-self;
  }
  -webkit-align-self: $align-self; // Newer iOS & BB
  align-self: $align-self; // All the cool kids
}

// *
// * Flex-align-content mixin: align-content property sets how multiple lines are spaced apart from each other
// * NOTE: accepted values: flex-start | flex-end | center | space-between | space-around | stretch (default)
// *
@mixin align-content($align-content: stretch) {
  // No Webkit Box Fallback
  @if ($align-content == flex-start) { // IE 10
    -ms-flex-line-pack: start;
  }
  @else if ($align-content == flex-end) {
    -ms-flex-line-pack: end;
  }
  @else {
    -ms-flex-line-pack: $align-content;
  }
  -webkit-align-content: $align-content; // Newer iOS & BB
  align-content: $align-content;  // All the cool kids
}
