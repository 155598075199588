// ** PAGER
.item-list-pager {
  margin: 20px 0;
  float: left;
  width: 100%;
}
ul.pager {
  display: table;
  margin: 0 auto;
  background: $white;
  text-align: center;
  @extend %m-p-reset;
  font-size: 0;
  border-bottom: 1px solid $border-color;

  li {
    @extend %m-p-reset;
    display: inline-block;
    //float: left;
    border-right: 0;
    font-size: 0;
    vertical-align: top;
    margin: 0 4px;
    line-height: 30px;
    a {
      position: relative;
      padding: 5px 10px 9px;
      display: block;
      text-transform: uppercase;
      @include font-size(11);
      @include transition(all $duration $timing);

      &:hover {
        font-weight: bold;
        &:before {
          font-weight: normal;
        }

        &:after {
          height: 4px;
          background: $yellow;
        }
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;

        width: 100%;
        height: 1px;
        background: transparent;
      }
    }
    &.ellipsis,
    &.current {
      color: $coil;
      @include font-size(11);
      padding: 5px 10px;
      border-right: 0;
    }
    &.current {
      display: inline-block;
      border-bottom: 4px solid $yellow;
      font-weight: bold;
    }

    &.next,
    &.last,
    &.first,
    &.previous {
      display: inline-block;
      a {
        font-size: 0;
        padding: 6px 11px;
        text-align: center;
        @include break1 {
          @include font-size(11);
          padding: 6px 20px;
        }

        &:before {
          position: absolute;
          height:100%;
          width: 28px;
          top: 1px;
          bottom: 1px;
          background: $light-grey;
          line-height: 28px;
        }
        &:after {
          content: none;
        }
      }
    }
    &.first,
    &.previous {
      a {
        @include break1 {
          padding-left: 48px;
        }
        &:before {
          left: 1px;
        }
      }
    }
    &.next,
    &.last {
      a {
        @include break1 {
          padding-right: 48px;
        }
        &:before {
          right: 1px;
          @include break1 {
            right: 0;
          }
        }
      }
    }
    $arrow-size: 17px;
    &.next {
      a {
        @include font-awesome($angle-right, lighten($coil, 40%), $arrow-size);
      }
    }
    &.last {
      margin-right: 0;
      a {
        //font-size: 0;
        @include font-awesome($angle-double-right, lighten($coil, 40%), $arrow-size);
      }
    }
    &.previous {
      a {
        @include font-awesome($angle-left, lighten($coil, 40%), $arrow-size);
      }
    }
    &.first {
      margin-left: 0;
      a {
        @include font-awesome($angle-double-left, lighten($coil, 40%), $arrow-size);
      }
    }
  }
}
