.agro_glue-homepage_izw {
  position: relative;
  z-index: 20;
  .content-wrapper,
  h3 {
    float: left;
    width: 100%;
    padding: 15px $gutter 0;
  }
  .content-wrapper {
    background: $white;
    float: left;
    width: 100%;
    padding-top: 0;
    @include break2max {
      //added to make tooltip on responsive go away when clicking
      cursor: pointer;
      -webkit-backface-visibility:  hidden;
      -webkit-tap-highlight-color: transparent;
    }
  }

  h3 {
    @extend %h2-title-wide;
    text-align: center;
    margin: 0 0 15px 0;
  }

  ul {
    list-style: none;
    float: left;
    width: 100%;
    margin: 0;
    padding-left: 0;
    li {
      position: relative;
      @include break1 {
        float: left;
        width: 50%;
        padding-right: 20px;

        &:nth-child(2n + 1) {
          clear: left;
        }
      }
      a {
        border-bottom: 1px solid transparent;
        margin-bottom: 8px;
        display: inline-block;

        &:hover {
          border-bottom: 1px solid $coil;
        }
      }
      @include break2 {
        &:hover {
          .tooltip {
            display: block;
          }
        }
      }
    }
    &.links {
      padding-top: 10px;
    }
  }
  ul.links a,
  .block-link {
    float: right;
    @include button;
    margin-bottom: -10px;
    padding: 8px 5px;
    display: block;
    text-align: center;
    width: 50%;
  }
  .block-link {
    margin-top: 10px;
  }
}
