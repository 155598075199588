.faq-faq_categories {
  background-color: $coil;

  .pane-title {
    color: $white;
    text-align: center;
  }
  > ul {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      margin-bottom: 20px;
      background: $coil;
      @include transition(all $duration $timing);
      a {
        position: relative;
        display: block;
        @extend %h2-title;
        text-transform: uppercase;
        font-weight: bold;
        color: $white;
        padding: 12px 40px;

        &.active {
          &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            width: 7px;
            height: 100%;
            background: $yellow;
          }
        }
      }
    }
  }
}
