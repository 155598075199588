.owl-carousel {
  position: relative;

  .owl-controls {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    margin: auto;
    height: 28px;
    .owl-buttons {
      .owl-prev {
        float: left;
        @include font-awesome($angle-left, $coil, 40px);
      }
      .owl-next {
        float: right;
        @include font-awesome($angle-right, $coil, 40px);
      }
      .owl-prev,
      .owl-next {
        opacity: 1;
        background: transparent;
        text-indent: -9999px;
        position: relative;
        width: 14px;
        overflow: hidden;
        margin: 0;
        padding: 0;
        &.disabled {
          opacity: 0;
          pointer-events: none;
          &:hover {
            opacity: 0;
          }
        }
        &:before {
          display: block;
          text-indent: 0;
          width: 14px;
          text-align: center;
          height: 28px;
        }
      }
    }
  }
}
