// -- GENERAL -- //

// ** BODY
body {
  @include font-size($base-font-size, 20);
  font-family: $base-font-family;
  color: $coil;
  font-weight: normal;
  background: $light-grey;
  & * {
    -webkit-font-smoothing: antialiased; //fix for Chrome font-rendering
  }
}

// ** HEADING
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 10px 0;
  text-transform: uppercase;
  font-weight: bold;
}
h1 {
  @extend %h1-title-underline;
}
h2 {
  @extend %h2-title;
}
h3 {
  @extend %h3-title;
}
h4 {
  @extend %h4-title;
}
h5 {
  color: $h5-colour;
  @include font-size($h5-size);
}
h6 {
  color: $h6-colour;
  @include font-size($h6-size);
  text-transform: initial;
}

// ** LINKS
a {
  text-decoration: none;
  @include links($coil, darken($coil, 10%));
  @include transition(all $duration $timing);
  &:after,
  &:before {
    @include transition(all $duration $timing);
  }
  &.button {
    @include button;
  }

  .field-type-text-with-summary &,
  .field-type-text-long & {
    text-decoration: underline;
  }
}

// ** LISTS
ul,
ol,
dl {
  @extend %m-p-reset;
  margin: 10px 0;
  padding-left: 10px;
  li {
    list-style-position: inside;
  }
}

// ** PARAGRAPHS
p {
  @extend %m-p-reset;
  margin: 20px 0;

  &.article-intro {
    font-weight: bold;

    .node-teaser & {
      font-weight: normal;
    }
  }
}

.strong,
b {
  font-weight: bold;
}

// ** TEXT STYLING DRUPAL STYLE
.field-type-text-with-summary,
.field-type-text-long,
.views-field-body {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
  }

  h2 {
    @extend %h2-body;
  }
  h3 {
    @extend %h3-body;
  }
  h4 {
    @extend %h4-body;
  }
  div,
  p {
    padding-left: 20px;
    margin-bottom: 10px;
    &.article-intro {
      padding-left: 0;
    }
  }

  div {
    &:last-child {
      margin-bottom: 0;
    }
  }
  p {
    margin-top: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  small {
    // Add custom styling
  }

  ul,
  ol,
  dl {
    @extend %m-p-reset;
    margin: 10px 0;
    padding-left: 10px;
  }
  ul {
    margin-left: 20px;
    margin-bottom: 10px;

    li {
      list-style: none;
      @include font-awesome($dot, $coil, 3px);
      position: relative;
      &:before {
        position: absolute;
        left: -8px;
        top: 0;
      }
    }
  }

  .submit,
  .button {
    @include button;
  }
}

// ** HR
hr {
  width: 100%;
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid grey;
  margin: 20px 0;
  padding: 0;
}

// ** BLOCKQUOTE
blockquote {
  margin: 0;
  padding: 20px;
  background-color: $white;
  width: 100%;
  border-left: 5px solid $yellow;
  &:before {
    color: lighten($black, 80%);
    content: '';
    font-size: 50px;
    line-height: 0.1em;
    margin-right: 10px;
    vertical-align: -20px;
  }
}

// ** PREFORMATTED TEXT
pre {
  margin: 0;
}

mark {
  color: $coil;
  background: $yellow;
}

// ** FLUID IFRAMES
.fluid-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  width: 100%;
  & iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
}
